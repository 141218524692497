import {
  GET_CONFIG,
  SAVE_CONFIG,
  GET_SEARCH_COMPANIES,
  GET_CLIENTS,
  GET_ACTIVE_CLIENTS,
  GET_CLIENT,
  SAVE_CLIENT,
  SAVE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE,
  SAVE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  DELETE_USER,
  GET_USER,
  SAVE_USER,
  GET_DATA_LIST,
  GET_DATA,
  DELETE_ASSOCIATION,
  GET_ASSOCIATIONS,
  SAVE_ASSOCIATION,
  GET_LOGIN_STATUS,
  SEND_INVITE,
  GET_LIST,
  GET_LIST_ERROR,
  SAVE_PROFILE,
  GET_PROFILE,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  SAVE_COMPANY,
  GET_COMPANY,
} from "../actions/types";

const initialState = {
  dropoffReport: {},
  data: {},
  associations: {},
  userData: {},
  profileData: {},
  dataList: {},
  documentData: {},
  clientList: {},
  documentList: {},
  dropdownList: {},
  clientData: {},
  locationData: {},
  locationList: {},
  getLoginStatus: {},
  saveData: {},
  printCode: {},
  ccResponse: {},
  priceData: {},
  priceList: {},
  reportData: {},
  outputData: {},
  companies: {},
};

export function pagesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST:
    case GET_LIST_ERROR:
      return {
        ...state,
        dropdownList: action.payload,
      };

    case GET_SEARCH_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };

    case DELETE_CLIENT:
    case DELETE_USER:
    case GET_CLIENTS:
    case GET_ACTIVE_CLIENTS:
    case SAVE_CLIENT:
      return { ...state, clientList: action.payload };

    case GET_CLIENT:
      return { ...state, clientData: action.payload };

    case GET_DOCUMENTS:
      return { ...state, documentList: action.payload };

    case GET_DOCUMENT:
      return { ...state, documentData: action.payload };

    case GET_USER:
      return { ...state, userData: action.payload };

    case GET_DATA_LIST:
      return { ...state, dataList: action.payload };

    case GET_DATA:
      return { ...state, data: action.payload };

    case DELETE_ASSOCIATION:
    case GET_ASSOCIATIONS:
    case SAVE_ASSOCIATION:
      return {
        ...state,
        associations: action.payload,
      };

    case GET_CONFIG:
    case SEND_INVITE:
    case GET_EMAIL_TEMPLATE:
    case GET_EMAIL_TEMPLATES:
    case DELETE_EMAIL_TEMPLATE:
    case SAVE_EMAIL_TEMPLATE:
      return {
        ...state,
        data: action.payload,
      };

    case SAVE_CONFIG:
    case SAVE_USER:
    case SAVE_CLIENT_SUCCESS:
    case DELETE_CLIENT_ERROR:
      return {
        ...state,
        saveData: action.payload,
      };

    case SAVE_PROFILE:
    case GET_PROFILE:
    case SAVE_COMPANY:
    case GET_COMPANY:
      return {
        ...state,
        profileData: action.payload,
      };

    case GET_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      };

    default:
      return state;
  }
}
