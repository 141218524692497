import React, { Component, Fragment } from "react";

class DocumentCenterBreadCrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentFolderPath: "",
    };
  }
  render() {
    let folderCount = 0;
    const { parentFolderPath, breadcrumb, clientId } = this.props;
    if (
      parentFolderPath !== "" &&
      parentFolderPath !== "/" &&
      parentFolderPath !== null &&
      typeof parentFolderPath !== "undefined"
    ) {
      return (
        <Fragment>
          {typeof breadcrumb !== "undefined" && (
            <Fragment>
              {breadcrumb.map((folder, index) => {
                if (folder !== "") {
                  folderCount++;
                  if (breadcrumb.length !== folderCount) {
                    return (
                      <li
                        key={index}
                        className="breadcrumb-item btn-link cursorClick"
                      >
                        <a
                          href={`/documents/${clientId}/${folder.folderId}${folder.path}`}
                        >
                          {folder.name}
                        </a>
                      </li>
                    );
                  } else {
                    return (
                      <li key={index} className="breadcrumb-item">
                        {folder.name}
                      </li>
                    );
                  }
                } else {
                  return null;
                }
              })}
            </Fragment>
          )}
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

export default DocumentCenterBreadCrumb;
