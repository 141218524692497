import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getCreateUser,
  sendUserInvite,
} from "../../../client/actions/apiActions";
import Loading from "../../layout/Loading";
import FormInput from "../../form/FormInput";
import FormInputCheckboxElement from "../../form/FormInputCheckboxElement";
import { compose } from "redux";
import { withRouter } from "../../../utils";

const ClientsInvite = (props) => {
  const clientCheckboxes = new Set();
  const folderCheckboxes = new Set();
  const { companyId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [inviteCompanyId, setInviteCompanyId] = React.useState("");
  const [inviteCompany, setInviteCompany] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [inviteEmail, setInviteEmail] = React.useState("");
  const [userRole, setUserRole] = React.useState("");
  const [customersChecked, setCustomersChecked] = React.useState("");
  const [foldersChecked, setFoldersChecked] = React.useState("");
  const [userRoles, setUserRoles] = React.useState([]);
  const [userRolePermissions, setUserRolePermissions] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errors, setErrors] = React.useState({});

  useEffect(() => {
    setInviteCompanyId(companyId);
    props.getCreateUser(companyId);
  }, []);

  useEffect(() => {
    let errors = {};
    if (props.dataList.userRoles !== userRoles) {
      setUserRoles(props.dataList.userRoles);
      setInviteCompany(props.dataList.companyName);
      setCustomers(props.dataList.customers);
    }

    if (props.data.status === 0) {
      if (props.data.message === "User already exists!") {
        errors["inviteEmail"] = props.data.message;
        setErrors(errors);
      }
      setErrorMessage(props.data.message);
    } else if (props.data.status === 1) {
      navigate("/clients");
    }
  }, [props]);

  const sendUserInvite = () => {
    let errors = {};
    let error = false;

    if (firstName === "") {
      error = true;
      errors["firstName"] = "You did not enter the users First Name!";
    }
    if (lastName === "") {
      error = true;
      errors["lastName"] = "You did not enter the users Last Name!";
    }
    if (userRole === "") {
      error = true;
      errors["userRole"] = "You did not select a user role!";
    }
    if (inviteEmail === "") {
      error = true;
      errors["inviteEmail"] = "You did not enter a user email!";
    }

    if (error === true) {
      setErrorMessage("You have errors!");
      setErrors(errors);
    }

    if (error === false)
      props.sendUserInvite(
        inviteEmail,
        inviteCompanyId,
        firstName,
        lastName,
        userRole,
        customersChecked,
        foldersChecked,
      );
  };

  const customerCheckboxClicked = (label) => {
    if (clientCheckboxes.has(label)) {
      clientCheckboxes.delete(label);
    } else {
      clientCheckboxes.add(label);
    }

    let customersChecked = ",";
    for (const checkbox of clientCheckboxes) {
      customersChecked += checkbox + ",";
    }
    setCustomersChecked(customersChecked);
  };

  const folderCheckboxClicked = (label) => {
    if (folderCheckboxes.has(label)) {
      folderCheckboxes.delete(label);
    } else {
      folderCheckboxes.add(label);
    }

    let foldersChecked = ",";
    for (const checkbox of folderCheckboxes) {
      foldersChecked += checkbox + ",";
    }
    setFoldersChecked(foldersChecked);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 m-auto">
          <div className="card card-body mt-5 ">
            <h1 className="text-center my-4">Invite New User</h1>
            <p>
              To invite a new user, select the company to assign them to and
              enter their email address.
            </p>
            <p>Note: If the company doesn't exist add the company first.</p>
            <hr noshade="true" />

            {errorMessage && (
              <div className="col-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            )}

            <FormInput
              error={errors.inviteCompany}
              label="Company"
              type="display"
              id="inviteCompany"
              name="inviteCompany"
              value={inviteCompany}
            />

            <FormInput
              error={errors.firstName}
              label="First Name"
              type="text"
              id="firstName"
              name="firstName"
              value={firstName}
              placeholder="enter first name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormInput
              error={errors.lastName}
              label="Last Name"
              type="text"
              id="lastName"
              name="lastName"
              value={lastName}
              placeholder="enter last name"
              onChange={(e) => setLastName(e.target.value)}
            />

            <FormInput
              error={errors.inviteEmail}
              label="Email Address"
              type="text"
              id="inviteEmail"
              name="inviteEmail"
              value={inviteEmail}
              placeholder="enter email address"
              onChange={(e) => setInviteEmail(e.target.value)}
            />

            <h3>Permissions</h3>
            <FormInput
              type="radio"
              className="form-control"
              name="userRole"
              label="Account Role"
              onChange={(e) => setUserRole(e.target.value)}
              placeholder=""
              value={userRole}
              valueOptions={userRoles}
              error={errors.userRole}
            />
            {userRolePermissions.viewCustomers === "Selectable" && (
              <div className="row">
                <div className="col-12">
                  <h3>Customer Permissions</h3>
                  <p>
                    Select Customer Account you wish this user to have access
                    to.
                  </p>

                  <table className="table table-sm small">
                    <thead className="thead-dark">
                      <tr>
                        <th>&nbsp;</th>
                        <th>Company Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customers.map((customer, index) => {
                        if (customer.primaryAccount !== "1") {
                          let customerChecked = false;
                          if (
                            typeof customersChecked !== "undefined" &&
                            customersChecked !== "" &&
                            customersChecked !== "," &&
                            customersChecked !== null
                          ) {
                            if (
                              customersChecked.indexOf(
                                "," + customer.id + ",",
                              ) !== -1
                            ) {
                              customerChecked = true;
                            }
                          }

                          return (
                            <tr key={index}>
                              <td className="text-right">
                                <FormInputCheckboxElement
                                  id={"companyChecked" + index}
                                  name={"companyChecked" + index}
                                  value={customer.id}
                                  checked={customerChecked}
                                  handleCheckboxChange={() =>
                                    customerCheckboxClicked(customer.id)
                                  }
                                />
                              </td>
                              <td>
                                <label htmlFor={"companyChecked" + index}>
                                  {" "}
                                  {customer.name}{" "}
                                </label>
                                {typeof customersChecked !== "undefined" &&
                                  customersChecked !== "" &&
                                  customersChecked !== "," &&
                                  customersChecked !== null && (
                                    <>
                                      {customersChecked.indexOf(
                                        "," + customer.id + ",",
                                      ) !== -1 &&
                                        userRolePermissions.viewCustomers ===
                                          "Selectable" &&
                                        customer.folders.length > 0 && (
                                          <>
                                            <br />
                                            <b>Folders</b>
                                            <br />
                                            <table className="table table-sm table-hover small">
                                              <thead className="thead-light">
                                                <tr>
                                                  <th>&nbsp;</th>
                                                  <th>Folder Name</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {customer.folders.map(
                                                  (folder, index2) => {
                                                    let folderChecked = false;
                                                    if (
                                                      typeof foldersChecked !==
                                                        "undefined" &&
                                                      foldersChecked !== "" &&
                                                      foldersChecked !== "," &&
                                                      foldersChecked !== null
                                                    ) {
                                                      if (
                                                        foldersChecked.indexOf(
                                                          "," + folder.id + ",",
                                                        ) !== -1
                                                      ) {
                                                        folderChecked = true;
                                                      }
                                                    }
                                                    return (
                                                      <tr key={index2}>
                                                        <td className="text-right">
                                                          <FormInputCheckboxElement
                                                            id={
                                                              "folderChecked" +
                                                              folder.id
                                                            }
                                                            name={
                                                              "folderChecked" +
                                                              folder.id
                                                            }
                                                            value={folder.id}
                                                            checked={
                                                              folderChecked
                                                            }
                                                            handleCheckboxChange={() =>
                                                              folderCheckboxClicked(
                                                                folder.id,
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                        <td>
                                                          <label
                                                            htmlFor={
                                                              "folderChecked" +
                                                              folder.id
                                                            }
                                                          >
                                                            {folder.fullPath}
                                                          </label>
                                                        </td>
                                                      </tr>
                                                    );
                                                  },
                                                )}
                                              </tbody>
                                            </table>
                                          </>
                                        )}
                                    </>
                                  )}
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <p>
              Note: Additional permission settings can not be managed through
              the invite screen. Edit the user once invited to edit additional
              settings.
            </p>

            <button
              type="submit"
              className="btn btn-success my-2"
              onClick={() => sendUserInvite()}
            >
              <i className="fas fa-save" /> SAVE
            </button>
            <Link className="btn btn-secondary my-2" to="/clients">
              <i className="fas fa-ban" /> CANCEL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dataList: state.pages.dataList,
  data: state.pages.data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getCreateUser, sendUserInvite }),
)(ClientsInvite);
