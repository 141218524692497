import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getDocuments,
  deleteFile,
  archiveFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
} from "../../../client/actions/apiActions";
import "react-confirm-alert/src/react-confirm-alert.css";

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      isLoaded: false,
    };
  }
  render() {
    const { searchResults, sortField, sortDir } = this.props;

    return (
      <Fragment>
        <table className="table table-sm table-hover table-striped">
          <thead>
            <tr className="thead-dark">
              <th
                className="cursorClick"
                onClick={() => {
                  this.updateSort("Name");
                }}
              >
                Name
                {sortField === "Name" && (
                  <Fragment>
                    {" "}
                    {sortDir === "ASC" && (
                      <i className="fas fa-sort-alpha-down" />
                    )}
                    {sortDir === "DESC" && (
                      <i className="fas fa-sort-alpha-up" />
                    )}
                  </Fragment>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length > 0 && (
              <Fragment>
                {searchResults.map((fileRow, index) => {
                  return (
                    <tr key={index} className="small">
                      <td valign="middle" className="align-middle">
                        <a
                          href={`/documents/${fileRow.customerId}/${fileRow.fullFolderId}/${fileRow.fullFolderPath}`}
                        >
                          /{fileRow.fullFolderPath}
                        </a>
                        / {fileRow.name} ({fileRow.fullFileName})
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            )}
            {searchResults.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  documentList: state.pages.documentList,
  dataList: state.pages.dataList,
  clientList: state.pages.clientList,
});
export default connect(mapStateToProps, {
  getDocuments,
  deleteFile,
  archiveFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
})(Document);
