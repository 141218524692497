import React from "react";

const FolderList = (props) => {
  const { userInfo, userRole, folders, editFolder, deleteFolder, clientId } =
    props;
  return (
    <>
      {folders.length > 0 && (
        <>
          {folders.map((folder, index) => {
            return (
              <tr key={index} className="small">
                <td nowrap="true" valign="middle" className="align-middle">
                  {folder.folderName !== "uploads" &&
                    folder.folderType !== "1" && (
                      <>
                        {(userRole.foldersAdd === "1" ||
                          userInfo.userType === "A") && (
                          <button
                            onClick={() => {
                              editFolder(
                                folder.id,
                                folder.fullPath,
                                folder.folderName,
                                folder.folderType,
                              );
                            }}
                            className="btn btn-success btn-sm m-1"
                          >
                            <i
                              className="fa fa-pencil-alt"
                              title="Edit Folder Details"
                            />
                          </button>
                        )}
                        {(userRole.foldersDelete === "1" ||
                          userInfo.userType === "A") && (
                          <button
                            onClick={() => deleteFolder(folder.id)}
                            className="btn btn-danger btn-sm m-1"
                          >
                            <i
                              className="fas fa-trash-alt"
                              title="Delete Folder"
                            />
                          </button>
                        )}
                      </>
                    )}{" "}
                  &nbsp;
                </td>
                <td valign="middle" className="align-middle">
                  {folder.folderType === "1" && (
                    <i className="fas fa-globe-americas" />
                  )}
                  {folder.folderType === "2" && (
                    <i className="fas fa-user-friends" />
                  )}
                  {folder.folderType === "3" && (
                    <i className="fas fa-user-lock" />
                  )}
                </td>
                <td colSpan="5" valign="middle" className="align-middle">
                  <a
                    href={`/documents/${clientId}/${folder.id}${folder.fullPath}`}
                  >
                    <i className="fas fa-folder" /> {folder.folderName}{" "}
                    {folder.fileCount && folder.fileCount !== "0" && (
                      <>({folder.fileCount} files)</>
                    )}
                  </a>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};
export default FolderList;
