
// CONFIG
export const SAVE_CONFIG = 'SAVE_CONFIG';
export const GET_CONFIG = 'GET_CONFIG';

// PROFILE
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';

// CLIENT
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_ACTIVE_CLIENTS = 'GET_ACTIVE_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const SAVE_CLIENT = 'SAVE_CLIENT';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_ERROR = 'SAVE_CLIENT_ERROR';
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR';
export const DELETE_CLIENT = 'DELETE_CLIENT';

// PEOPLE
export const DELETE_USER = 'DELETE_USER';
export const SAVE_USER = 'SAVE_USER';
export const GET_USER = 'GET_USER';

// ASSOCIATIONS
export const DELETE_ASSOCIATION = 'DELETE_ASSOCIATION';
export const SAVE_ASSOCIATION = 'SAVE_ASSOCIATION';
export const GET_ASSOCIATIONS = 'GET_ASSOCIATIONS';

export const GET_SEARCH_COMPANIES = 'GET_SEARCH_COMPANIES';

// COMPANY
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';

// MESSAGE
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';

// USER AUTH / INVITE
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';
export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';

export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS';
export const FORGOT_PASS_FAIL = 'FORGOT_PASS_FAIL';
export const SAVE_FORGOT_PASS = 'SAVE_FORGOT_PASS';
export const CHECK_VERIFICATION_CODE = 'CHECK_VERIFICATION_CODE';
export const SEND_INVITE = 'SEND_INVITE';

// DOCUMENTS
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENT = 'GET_DOCUMENT';

// LISTS
export const GET_LIST = 'GET_LIST';
export const GET_LIST_ERROR = 'GET_LIST_ERROR';

// EMAIL TEMPLATES
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const SAVE_EMAIL_TEMPLATE = 'SAVE_EMAIL_TEMPLATE';
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';

// DATA
export const GET_DATA_LIST = 'GET_DATA_LIST';
export const GET_DATA = 'GET_DATA';