import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  saveDefaultFolder,
  getDefaultFolder,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import FormInput from "../../form/FormInput";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class Config_Default_Folder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      folderName: "",
      parentFolder: "",
      parentFolders: [],
      successMessage: "",
      error: {},
      pageType: "",
      folderType: "",
      folderTypes: [],
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveDefaultFolder: PropTypes.func.isRequired,
    getDefaultFolder: PropTypes.func.isRequired,
  };
  componentDidMount() {
    let { id } = this.props.router.params;
    this.props.getDefaultFolder(id);
    if (typeof id !== "undefined" && id !== "0") {
      this.props.getDefaultFolder(id);
      this.setState({ pageType: "Edit" });
    } else {
      this.setState({ pageType: "Add", data: {} });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { initialDataLoaded } = this.state;
    if (initialDataLoaded === false && typeof nextProps.data !== "undefined") {
      let folderType = "";
      if (nextProps.data.folderType !== "") {
        folderType = nextProps.data.folderType;
      } else {
        folderType = 2;
      }

      this.setState({
        initialDataLoaded: true,
        data: nextProps.data,
        folderName: nextProps.data.folderName,
        fullPath: nextProps.data.fullPath,
        parentFolder: nextProps.data.parentFolder,
        parentFolders: nextProps.data.parentFolders,
        folderType: folderType,
        folderTypes: nextProps.data.folderTypes,
      });
    }
    if (nextProps.data.success === "1" || nextProps.data.success === 1) {
      this.props.router.navigate("/config/default-folders");
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onCancel = () => {
    this.props.router.navigate("/config/default-folders");
  };
  onEditorChange = (e) => {
    let message = e.target.getContent();
    this.setState({ message: message });
  };
  onSave = () => {
    let id;
    if (this.props.match.params.id !== "") {
      id = this.props.match.params.id;
    } else {
      id = "";
    }
    const { folderName, parentFolder, folderType } = this.state;
    this.props.saveDefaultFolder(id, folderName, parentFolder, folderType);
    //this.props.router.navigate('/config/default-folders');
  };
  render() {
    const {
      successMessage,
      data,
      error,
      folderName,
      parentFolder,
      parentFolders,
      pageType,
      folderType,
      folderTypes,
    } = this.state;

    if (typeof data !== "undefined") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">{pageType} Folder</h1>
            </div>
            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}

            <div className="container">
              <div className="row boxWhite">
                <div className="col-12">
                  <FormInput
                    type="dropdown"
                    className="form-control"
                    name="folderType"
                    label="Folder Type"
                    onChange={this.onChange}
                    placeholder=""
                    dropdown={folderTypes}
                    value={folderType}
                    error={error.folderType}
                  />
                </div>
                <div className="col-12">
                  <FormInput
                    type="dropdown"
                    className="form-control"
                    name="parentFolder"
                    label="Parent Folder Name"
                    onChange={this.onChange}
                    placeholder=""
                    dropdown={parentFolders}
                    value={parentFolder}
                    error={error.parentFolder}
                  />
                </div>
                <div className="col-12">
                  <FormInput
                    type="input"
                    className="form-control"
                    name="folderName"
                    label="Folder Name"
                    onChange={this.onChange}
                    placeholder=""
                    value={folderName}
                    error={error.folderName}
                  />
                </div>

                <div className="col-sm-6  mb-3">
                  <button
                    type="submit"
                    className="btn btn-dark"
                    onClick={() => this.onCancel()}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-sm-6 text-right mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => this.onSave()}
                  >
                    SAVE
                  </button>
                </div>
                <div className="col-12 mt-4">
                  <h2>Notes</h2>
                  <p>
                    <b>Global Folder</b> - This folder type will be singular.
                    All files uploaded to it will be visible to all clients.
                  </p>
                  <p>
                    <b>Unique Folder</b> - This folder type will be unique to
                    each business. All business will have their own version of
                    this folder. Files uploaded to it will only be visible by
                    that business and the users assigned to it.
                  </p>
                  <p>
                    <b>Private Folder</b> - This folder type will be unique to
                    each business. All business will have their own version of
                    this folder. Files uploaded to it will only be visible by{" "}
                    <u>
                      <b>your business</b>
                    </u>{" "}
                    and{" "}
                    <u>
                      <b>your users</b>
                    </u>{" "}
                    that are assigned to it and not the business owner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
  saveData: state.pages.saveData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    saveDefaultFolder,
    getDefaultFolder,
  }),
)(Config_Default_Folder);
