import { Link } from "react-router-dom";
import TabBusinessesClientUsers from "./TabBusinessesClientUsers";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  activateClient,
  deleteClient,
} from "../../../client/actions/apiActions";
import { connect } from "react-redux";

const TabBusinessesClients = (props) => {
  const { client, setSuccessMessage, userInfo, userRole } = props;

  const deleteClient = (clientId, clientName) => {
    const deleteOptions = {
      title: "Confirm Archive",
      message: `Are you sure you wish to archive this client "${clientName}"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.deleteClient(clientId);
            setSuccessMessage("Client has been archived!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    };
    confirmAlert(deleteOptions);
  };

  const activateClient = (clientId, clientName) => {
    const deleteOptions = {
      title: "Confirm Activate",
      message: `Are you sure you wish to activate this client "${clientName}"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.activateClient(clientId);
            setSuccessMessage("Client has been activated!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    };
    confirmAlert(deleteOptions);
  };

  return (
    <>
      <tr className="bg-primary text-light">
        <td nowrap="true" className="align-middle">
          {client.name}
        </td>
        <td nowrap="true" className="align-middle">
          {client.phone}
        </td>
        <td nowrap="true" className="align-middle">
          {client.email}
        </td>
        <td nowrap="true" className="text-right">
          {(userRole.usersCanInvite === "1" || userInfo.userType === "A") && (
            <a
              title="Invite new user"
              className="btn btn-success btn-sm m-1"
              href={"/clients/invite/" + client.id}
            >
              <i className="fas fa-plus-square" />
            </a>
          )}

          {(userRole.companyCanEdit === "1" || userInfo.userType === "A") && (
            <a
              title="Edit Company Info"
              className="btn btn-warning btn-sm m-1"
              href={"/clients/client_edit/" + client.id}
            >
              <i className="fas fa-pencil-alt" />
            </a>
          )}

          {(userRole.companyCanDelete === "1" || userInfo.userType === "A") && (
            <>
              {client.owner === "0" && (
                <>
                  {client.active === "1" && (
                    <button
                      title="Disable Company"
                      className="btn btn-dark btn-sm m-1"
                      onClick={() => deleteClient(client.id, client.name)}
                    >
                      <i className="fas fa-archive" />
                    </button>
                  )}

                  {client.active === "0" && (
                    <button
                      title="Re-Enable Company"
                      className="btn btn-primary btn-sm m-1"
                      onClick={() => activateClient(client.id, client.name)}
                    >
                      <i className="fas fa-archive" />
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </td>
      </tr>
      <tr>
        <td nowrap="true" colSpan="4" className="p-0 m-0">
          <table className="table table-sm table-striped table-hover">
            <thead className="thead-dark small">
              <tr>
                <th nowrap="true" className="align-middle">
                  Name
                </th>
                <th nowrap="true" className="align-middle">
                  Phone
                </th>
                <th nowrap="true" className="align-middle">
                  Email
                </th>
                <th nowrap="true" className="align-middle text-center">
                  Locked
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {typeof client.users !== "undefined" && (
                <>
                  {client.users.map((user) => (
                    <TabBusinessesClientUsers
                      user={user}
                      key={`clientUser${client.id}-${user.id}`}
                      setSuccessMessage={setSuccessMessage}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
  userRole: state.pages.clientList.userRole,
  userInfo: state.pages.clientList.user,
});

export default connect(mapStateToProps, {
  activateClient,
  deleteClient,
})(TabBusinessesClients);
