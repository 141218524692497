import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getClients } from "../../../client/actions/apiActions";
import { Tabs, Tab } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import Loading from "../../layout/Loading";
import "react-confirm-alert/src/react-confirm-alert.css";
import TabBusinesses from "./TabBusinesses";
import TabUsers from "./TabUsers";

const Client = (props) => {
  const { userInfo } = props;

  const [clientList, setClientList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [tabKey, setTabKey] = useState("clients");
  const [loading, setLoading] = useState(true);

  const timeOut = 5000;
  const showCloseButton = true;
  const progressBar = true;
  const position = "top-right";

  const showToastr = (type, title, message) => {
    const options = {
      timeOut: timeOut,
      showCloseButton: showCloseButton,
      progressBar: progressBar,
      position: position,
    };
    const toastrInstance =
      type === "info"
        ? toastr.info
        : type === "warning"
        ? toastr.warning
        : type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance(title, message, options);
  };

  useEffect(() => {
    props.getClients();
  }, []);

  useEffect(() => {
    if (typeof props.data !== "undefined") {
      if (typeof props.data.success !== "undefined") {
        if (props.data.success === 1) {
          showToastr(
            "success",
            "Email Sent",
            `Email to ${props.data.name} has been resent!`,
          );
        }
        if (props.data.success === 0) {
          showToastr(
            "error",
            "Email Failed",
            `Sending email failed. ${props.data.message}`,
          );
        }
      }
    }

    if (props.clientList.displayType === "Clients") {
      setLoading(false);
      if (clientList !== props.clientList.clients) {
        setClientList(props.clientList.clients);
        setUserList(props.clientList.users);
      }
    }
  }, [props]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center my-4">Businesses</h1>
          {successMessage && (
            <div className="col-12">
              <div className="alert alert-success">{successMessage}</div>
            </div>
          )}
          <Tabs
            id="userClients"
            activeKey={tabKey}
            onSelect={(selectedTab) => setTabKey(selectedTab)}
          >
            <Tab className="boxWhite" eventKey="clients" title="Businesses">
              <TabBusinesses
                clientList={clientList}
                setSuccessMessage={setSuccessMessage}
              />
            </Tab>
            {userInfo?.userType === "A" && (
              <Tab className="boxWhite" eventKey="users" title="Users">
                <TabUsers
                  userList={userList}
                  setSuccessMessage={setSuccessMessage}
                />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientList: state.pages.clientList,
  userRole: state.pages.clientList.userRole,
  userInfo: state.pages.clientList.user,
  data: state.pages,
});

export default connect(mapStateToProps, {
  getClients,
})(Client);
