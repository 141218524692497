import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class ProfileNavigation extends Component {
  render() {
    const { user } = this.props;
    return (
      <div className="col-4">
        <Link to="/profile/edit" className="btn btn-block btn-secondary">
          Edit Profile
        </Link>
        <Link to="/profile/password" className="btn btn-block btn-secondary">
          Change Password
        </Link>
        <Link
          to="/profile/notifications"
          className="btn btn-block btn-secondary"
        >
          Edit Notifications
        </Link>
        {user.associationType === "Company" && (
          <Link to="/profile/company" className="btn btn-block btn-secondary">
            Edit Company Info
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user.user,
});
export default connect(mapStateToProps)(ProfileNavigation);
