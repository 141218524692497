import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.css";
import "./assets/toastr.css";
import "./assets/toastr-animated.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <App
    ref={(App) => {
      window.App = App;
    }}
  />,
  document.getElementById("root"),
);
serviceWorker.unregister();
