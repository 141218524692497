import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfileNavigation from "./ProfileNavigation";
import PasswordStrengthMeter from "../../component/PasswordStrengthMeter";

import { saveProfilePassword } from "../../../client/actions/apiActions";

class ProfilePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      password: "",
      confirmPassword: "",
      currentPassword: "",
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (typeof nextProps.profileData.success !== "undefined") {
      if (
        nextProps.profileData.success === "1" ||
        nextProps.profileData.success === 1
      ) {
        this.setState({ successMessage: nextProps.profileData.message });
      }
    }
    if (typeof nextProps.profileData.error !== "undefined") {
      if (
        nextProps.profileData.error === "1" ||
        nextProps.profileData.error === 1
      ) {
        this.setState({ errorMessage: nextProps.profileData.message });
      }
    }
  }
  static propTypes = {
    profileData: PropTypes.object.isRequired,
    saveProfilePassword: PropTypes.func.isRequired,
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveProfilePassword = () => {
    const { password, confirmPassword, currentPassword } = this.state;
    this.setState({ errorMessage: "", successMessage: "" });
    this.props.saveProfilePassword(password, confirmPassword, currentPassword);
  };
  render() {
    const {
      password,
      confirmPassword,
      currentPassword,
      successMessage,
      errorMessage,
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">Edit Profile</h1>
          </div>
          <ProfileNavigation />
          <div className="col-8">
            <h2>Enter Current Password</h2>
            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}
            {errorMessage && (
              <div className="col-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            )}

            <div className="form-group row">
              <label
                className="col-sm-4 col-form-label"
                htmlFor="currentPassword"
              >
                Current Password
              </label>
              <div className="col-sm-8">
                <input
                  type="password"
                  className="form-control"
                  id="currentPassword"
                  name="currentPassword"
                  onChange={this.onChange}
                  placeholder=""
                  value={currentPassword}
                />
              </div>
            </div>
            <h2>Enter New Password</h2>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="password">
                Password
              </label>
              <div className="col-sm-8">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={this.onChange}
                  placeholder=""
                  value={password}
                />
                <br />
                <PasswordStrengthMeter password={password} />
              </div>
            </div>
            <div className="form-group row">
              <label
                className="col-sm-4 col-form-label"
                htmlFor="confirmPassword"
              >
                Confirm Password
              </label>
              <div className="col-sm-8">
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={this.onChange}
                  placeholder=""
                  value={confirmPassword}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-secondary "
              onClick={() => this.saveProfilePassword()}
            >
              <i className="fas fa-save" /> SAVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.pages.profileData,
  user: state.auth.user.user,
});

export default connect(mapStateToProps, { saveProfilePassword })(
  ProfilePassword,
);
