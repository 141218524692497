import React from "react";
import { deleteUser, resendInvite } from "../../../client/actions/apiActions";
import { connect } from "react-redux";
import UserList from "./TabUsersUserList";
import Loading from "../../layout/Loading";

const Users = (props) => {
  const { userList, setSuccessMessage } = props;
  if (!userList) {
    return <Loading />;
  }
  return (
    <div className="table-responsive">
      <h2 className="my-2 ml-2">Users</h2>
      <table className="table table-sm table-hover table-striped">
        <thead>
          <tr className="thead-dark">
            <th nowrap="true" className="align-middle">
              Name
            </th>
            <th nowrap="true" className="align-middle">
              Phone
            </th>
            <th nowrap="true" className="align-middle">
              Email
            </th>
            <th nowrap="true" className="align-middle text-center">
              Locked
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((client) => (
            <UserList
              key={`clientUser-${client.id}`}
              client={client}
              setSuccessMessage={setSuccessMessage}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, { deleteUser, resendInvite })(Users);
