import React, {Component} from 'react';

import FormInputText from './FormInputText';
import FormInputDisplay from './FormInputDisplay';
import FormInputTextArea from './FormInputTextArea';
import FormInputRadio from './FormInputRadio';
import FormInputFile from './FormInputFile';
import FormInputImage from './FormInputImage';
import FormInputSelect from './FormInputSelect';
import FormInputCheckbox from './FormInputCheckbox';
import FormInputEditor from './FormInputEditor';
import FormInputToggle from './FormInputToggle';
import FormInputPassword from './FormInputPassword';

class FormInput extends Component {
    render() {
        let showOneColumn = this.props.showOneColumn;
        if (typeof(showOneColumn) === 'undefined' || showOneColumn === '') showOneColumn='false';
        const {
            name,
            label,
            placeholder,
            type,
            helpText,
            value,
            dropdown,
            defaultValue,
            valueOptions,
            error,
            onUpload,
            onChange,
            onDeleteImage,
            onDeleteFile,
            onValueChange,
            onChangeToggle,
            handleEditorChange,
            handleCheckboxChange,
            uploadFolder
        } = this.props;

        switch(type) {
            case "display":
                return (<FormInputDisplay
                    name={name}
                    value={value}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "text":
                return (<FormInputText
                    showOneColumn={showOneColumn}
                    name={name}
                    value={value}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "password":
                return (
                    <FormInputPassword
                        type="password"
                        label={label}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        helpText={helpText}
                        onChange={onChange}
                        error={error}
                    />
                );

            case "editor":
                return (<FormInputEditor
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    handleEditorChange={handleEditorChange}
                    error={error}
                />);

            case "textarea":
                return (<FormInputTextArea
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "number":
                return (<FormInputText
                    showOneColumn={showOneColumn}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "radio":
                return (<FormInputRadio
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "toggle":
                return (<FormInputToggle
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChangeToggle={onChangeToggle}
                    error={error}
                />);

            case "checkbox":
                return (<FormInputCheckbox
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    handleCheckboxChange={handleCheckboxChange}
                    error={error}
                />);

            case "select":
            case "dropdown":
                return (<FormInputSelect
                    showOneColumn={showOneColumn}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    dropdown={dropdown}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "email":
                return (<FormInputText
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);

            case "upload":
            case "image":
                return (<FormInputImage
                    name={name}
                    value={value}
                    label={label}
                    placeholder={placeholder}
                    helpText={helpText}
                    onUpload={onUpload}
                    onDeleteImage={onDeleteImage}
                    error={error}
                />);

            case "file":
                return (<FormInputFile
                    name={name}
                    value={value}
                    label={label}
                    placeholder={placeholder}
                    helpText={helpText}
                    onUpload={onUpload}
                    onValueChange={onValueChange}
                    onChange={onChange}
                    uploadFolder={uploadFolder}
                    onDeleteFile={onDeleteFile}
                    error={error}
                />);

            default:
                return (<FormInputText
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    valueOptions={valueOptions}
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    helpText={helpText}
                    onChange={onChange}
                    error={error}
                />);
        }
    }
}

export default FormInput