import React from "react";

const DocumentListHeader = (props) => {
  const { sortField, sortDir, updateSort } = props;
  return (
    <thead>
      <tr className="thead-dark">
        <th nowrap="true">&nbsp;</th>
        <th nowrap="true">&nbsp;</th>
        <th
          nowrap="true"
          className="cursorClick"
          onClick={() => {
            updateSort("Name");
          }}
        >
          Name
          {sortField === "Name" && (
            <>
              {" "}
              {sortDir === "ASC" && <i className="fas fa-sort-alpha-down" />}
              {sortDir === "DESC" && <i className="fas fa-sort-alpha-up" />}
            </>
          )}
        </th>
        <th
          nowrap="true"
          className="cursorClick"
          onClick={() => {
            updateSort("Filename");
          }}
        >
          Filename
          {sortField === "Filename" && (
            <>
              {" "}
              {sortDir === "ASC" && <i className="fas fa-sort-alpha-down" />}
              {sortDir === "DESC" && <i className="fas fa-sort-alpha-up" />}
            </>
          )}
        </th>
        <th
          nowrap="true"
          className="cursorClick"
          onClick={() => {
            updateSort("Date");
          }}
        >
          Date Uploaded
          {sortField === "Date" && (
            <>
              {" "}
              {sortDir === "ASC" && <i className="fas fa-sort-alpha-down" />}
              {sortDir === "DESC" && <i className="fas fa-sort-alpha-up" />}
            </>
          )}
        </th>
        <th
          nowrap="true"
          className="cursorClick"
          onClick={() => {
            updateSort("Type");
          }}
        >
          Type
          {sortField === "Type" && (
            <>
              {" "}
              {sortDir === "ASC" && <i className="fas fa-sort-alpha-down" />}
              {sortDir === "DESC" && <i className="fas fa-sort-alpha-up" />}
            </>
          )}
        </th>
        <th
          nowrap="true"
          className="cursorClick"
          onClick={() => {
            updateSort("Size");
          }}
        >
          Size
          {sortField === "Size" && (
            <>
              {" "}
              {sortDir === "ASC" && <i className="fas fa-sort-alpha-down" />}
              {sortDir === "DESC" && <i className="fas fa-sort-alpha-up" />}
            </>
          )}
        </th>
      </tr>
    </thead>
  );
};

export default DocumentListHeader;
