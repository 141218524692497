import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import {
  deleteEmailTemplate,
  getEmailTemplates,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";

class Config_Email_Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: "",
      error: {},
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    deleteEmailTemplate: PropTypes.func.isRequired,
    getEmailTemplates: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getEmailTemplates();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (typeof nextProps.data !== "undefined") {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  onDelete = (deleteId) => {
    this.props.deleteEmailTemplate(deleteId);
  };

  render() {
    const { successMessage, data } = this.state;

    if (typeof data !== "undefined") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">Email Template</h1>
            </div>

            <div className="col-12 mb-2">
              <Link to="/config" className="btn btn-sm btn-secondary">
                Back
              </Link>
            </div>

            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}

            <div className="container">
              <div className="row boxWhite">
                <Link
                  to="/config/email-templates/0"
                  className="btn btn-primary btn-sm m-1"
                >
                  Add Email Template
                </Link>
                <table className="table table-sm table-striped table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Template Name</th>
                      <th scope="col">From</th>
                      <th scope="col">Subject</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof data.emailTemplates !== "undefined" && (
                      <Fragment>
                        {data.emailTemplates.map((template, index) => (
                          <tr key={index}>
                            <td className="align-middle">{template.name}</td>
                            <td className="align-middle">
                              {template.fromName} &lt; {template.fromEmail} &gt;{" "}
                            </td>
                            <td className="align-middle">{template.subject}</td>
                            <td
                              className="align-middle"
                              style={{ minWidth: "100px" }}
                            >
                              <Link
                                to={"/config/email-templates/" + template.id}
                                className="btn btn-primary mr-1 btn-sm"
                              >
                                <i className="fas fa-edit" />
                              </Link>
                              <Link
                                to="/config/email-templates/"
                                onClick={() => {
                                  window.confirm(
                                    "Are you sure you wish to delete this item?",
                                  ) && this.onDelete(template.id);
                                }}
                                className="btn btn-danger mr-1 btn-sm"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  deleteEmailTemplate,
  getEmailTemplates,
})(Config_Email_Templates);
