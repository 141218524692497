import DocumentCenterBreadCrumb from "./DocumentCenterBreadCrumb";
import React from "react";
import DocumentListHeader from "./DocumentListHeader";
import FileList from "./FileList";
import FolderList from "./FolderList";

const DocumentList = (props) => {
  const {
    clientId,
    clientName,
    breadcrumb,
    folders,
    files,
    parentFolderPath,
    folderType,
    userInfo,
    userRole,
    sortDir,
    sortField,
    tmpSearchTerm,
    onChange,
    updateSort,
    searchFunction,
    clickUpload,
    addFolder,
    editFolder,
    deleteFolder,
    editFile,
    deleteFile,
    moveFile,
    downloadFile,
  } = props;
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item btn-link cursorClick">
            <a href="/documents">Businesses</a>
          </li>
          {clientId !== 0 &&
            clientId !== null &&
            typeof clientId !== "undefined" &&
            parentFolderPath === "/" && (
              <li className="breadcrumb-item">{clientName}</li>
            )}
          {clientId !== 0 &&
            clientId !== null &&
            typeof clientId !== "undefined" &&
            parentFolderPath !== "/" && (
              <li className="breadcrumb-item btn-link cursorClick">
                <a href={`/documents/${clientId}`}>{clientName}</a>
              </li>
            )}

          {parentFolderPath !== "" && (
            <DocumentCenterBreadCrumb
              breadcrumb={breadcrumb}
              clientId={clientId}
              parentFolderPath={parentFolderPath}
              sortDir={sortDir}
              sortField={sortField}
            />
          )}
        </ol>
      </nav>
      <div className="col-12">
        <h3>Files List</h3>
        <p>Select the folder / file to view.</p>
        <div className="d-flex bd-highlight mb-3 row">
          <div className="col-sm-6 col-12 mr-auto p-2 bd-highlight">
            {((folderType === 1 && userInfo.userType === "A") ||
              (folderType !== 1 &&
                userRole.filesCanUpload === "1" &&
                userRole.roleType === "1") ||
              (folderType !== 1 && userInfo.userType === "A") ||
              parentFolderPath === "/uploads") && (
              <button
                className="btn btn-primary btn-sm mb-2"
                onClick={() => clickUpload()}
              >
                <i className="fas fa-file-medical" /> Upload New Document
              </button>
            )}{" "}
            {((folderType === 1 && userInfo.userType === "A") ||
              (folderType !== 1 &&
                userRole.foldersAdd === "1" &&
                userRole.roleType === "1") ||
              (folderType !== 1 && userInfo.userType === "A")) &&
              parentFolderPath !== "/uploads" && (
                <button
                  className="btn btn-secondary btn-sm mb-2"
                  onClick={addFolder}
                >
                  <i className="fas fa-folder-plus" /> Create Folder
                </button>
              )}
          </div>
          <div className="col-12 col-sm-6 p-2 bd-highlight">
            <form
              className="form-inline my-2 my-sm-0"
              method="post"
              action="/documents/search"
              id="searchscript"
              name="searchscript"
              onSubmit={searchFunction}
            >
              <input
                onChange={onChange}
                className="form-control form-control-sm mr-sm-2"
                id="tmpSearchTerm"
                name="tmpSearchTerm"
                type="search"
                placeholder="Search"
                value={tmpSearchTerm}
                aria-label="Search"
              />
              <a
                className="btn btn-primary btn-sm my-2 my-sm-0"
                onClick={searchFunction}
                href="/documents/search"
              >
                <i className="fas fa-search" />
              </a>
            </form>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-sm table-hover table-striped">
            <DocumentListHeader
              sortField={sortField}
              sortDir={sortDir}
              updateSort={updateSort}
            />
            <tbody>
              <FolderList
                userInfo={userInfo}
                userRole={userRole}
                folders={folders}
                editFolder={editFolder}
                deleteFolder={deleteFolder}
                clientId={clientId}
              />

              <FileList
                userInfo={userInfo}
                userRole={userRole}
                files={files}
                moveFile={moveFile}
                deleteFile={deleteFile}
                editFile={editFile}
                downloadFile={downloadFile}
                folders={folders}
              />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default DocumentList;
