import React, {Component} from 'react';

class Error404 extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center my-4">Error 404: Page Not Found</h1>
                        <p className="text-center">The page you are trying to reach does not exist</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Error404