import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveUser, getUser } from "../../../client/actions/apiActions";

import Loading from "../../layout/Loading";
import ItemText from "../../component/itemText";
import Toggle from "react-toggle";
import FormInput from "../../form/FormInput";
import FormInputCheckboxElement from "../../form/FormInputCheckboxElement";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSaving: false,

      // USER INFO
      firstName: "",
      lastName: "",
      email: "",
      phone: {},
      accountLocked: false,

      // PERMISSIONS
      customersChecked: "",
      foldersChecked: "",

      user: {},
      customers: {},
      userRolePermissions: {},
      successMessage: "",
      errorMessage: "",
      errors: {},
    };
    this.clientCheckboxes = new Set();
    this.folderCheckboxes = new Set();
  }
  componentDidMount() {
    try {
      let { id } = this.props.router.params;
      this.props.getUser(id);
    } catch (e) {
      console.log("e", e);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (typeof nextProps.userData !== "undefined") {
      const {
        firstName,
        lastName,
        email,
        phone,
        accountLocked,
        userRoles,
        userRole,
        customers,
        customersChecked,
        foldersChecked,
      } = nextProps.userData;

      let newAccountLocked = false;
      if (accountLocked === "1") newAccountLocked = true;

      let userRolePermissions = {};

      userRoles.map((role) => {
        if (role.value === userRole) {
          userRolePermissions = role;
        }
        return null;
      });
      this.setState({
        isLoading: false,
        user: nextProps.userData,
        firstName,
        lastName,
        email,
        phone,
        userRole,
        accountLocked: newAccountLocked,
        userRoles,
        userRolePermissions,
        customers,
        customersChecked,
        foldersChecked,
      });

      let customersCheckedArray = [];
      if (customersChecked !== null) {
        customersCheckedArray = customersChecked.split(",");
      }
      if (customersCheckedArray.length !== 0) {
        customersCheckedArray.map((customer) => {
          this.customerCheckboxClicked(customer.toString());
          return { ...customer };
        });
      }

      let foldersCheckedArray = [];
      if (foldersChecked !== null) {
        foldersCheckedArray = foldersChecked.split(",");
      }
      if (foldersCheckedArray.length !== 0) {
        foldersCheckedArray.map((folder) => {
          this.folderCheckboxClicked(folder.toString());
          return { ...folder };
        });
      }
    }
  }
  static propTypes = {
    userData: PropTypes.object.isRequired,
    saveUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    if (e.target.name === "userRole") {
      let userRole = e.target.value;
      let userRoles = this.state.userRoles;

      userRoles.map((role, index) => {
        if (role.value === userRole) {
          this.setState({
            userRolePermissions: role,
            [e.target.name]: e.target.value,
          });
        }
        return null;
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onChangeRadio = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  hangleToggleChange = (e) => {
    const { administrator, accountLocked } = this.state;
    if (e.target.name === "administrator")
      this.setState({ administrator: !administrator });
    if (e.target.name === "accountLocked")
      this.setState({ accountLocked: !accountLocked });
  };
  saveUser = () => {
    let { id } = this.props.router.params;
    const {
      firstName,
      lastName,
      email,
      phone,
      administrator,
      accountLocked,
      userRole,
      customersChecked,
      foldersChecked,
    } = this.state;

    let noerror = true;
    let errors = {};
    if (firstName === "") {
      noerror = false;
      errors = {
        ...errors,
        firstName: 'You left the users "First Name" blank',
      };
    }
    if (lastName === "") {
      noerror = false;
      errors = { ...errors, lastName: 'You left the users "Last Name" blank' };
    }
    if (email === "") {
      noerror = false;
      errors = { ...errors, email: 'You left the users "Email" blank' };
    }
    if (phone === "") {
      noerror = false;
      errors = { ...errors, phone: 'You left the users "Phone" blank' };
    }
    if (noerror === true) {
      this.setState({ isSaving: true });
      this.props.saveUser(
        id,
        firstName,
        lastName,
        email,
        phone,
        administrator,
        accountLocked,
        userRole,
        customersChecked,
        foldersChecked,
      );
      this.props.router.navigate("/clients");
    } else {
      this.setState({ errors, errorMessage: "there was an error" });
    }
  };

  cancelEdit = () => {
    this.props.router.navigate("/clients");
  };
  customerCheckboxClicked = (label) => {
    if (this.clientCheckboxes.has(label)) {
      this.clientCheckboxes.delete(label);
    } else {
      this.clientCheckboxes.add(label);
    }

    let customersChecked = ",";
    for (const checkbox of this.clientCheckboxes) {
      customersChecked += checkbox + ",";
    }
    this.setState({ customersChecked: customersChecked });
  };
  folderCheckboxClicked = (label) => {
    if (this.folderCheckboxes.has(label)) {
      this.folderCheckboxes.delete(label);
    } else {
      this.folderCheckboxes.add(label);
    }

    let foldersChecked = ",";
    for (const checkbox of this.folderCheckboxes) {
      foldersChecked += checkbox + ",";
    }
    this.setState({ foldersChecked: foldersChecked });
  };
  onSubmitForm = (e) => {
    e.preventDefault();
  };

  customerCheckAllClicked = (customerId, checkedValue) => {
    let outputValue;
    if (typeof checkedValue === "undefined") {
      outputValue = false;
    } else {
      outputValue = !checkedValue;
    }
    this.setState({ ["companyAllChecked" + customerId]: outputValue });
  };

  render() {
    const { isLoading, isSaving, errors, errorMessage } = this.state;

    if (isLoading === false) {
      const {
        firstName,
        lastName,
        email,
        phone,
        accountLocked,
        userRole,
        userRoles,
        userRolePermissions,
        customers,
        customersChecked,
        foldersChecked,
      } = this.state;
      return (
        <div className="container">
          <form
            name="editClientForm"
            id="editClientForm"
            autoComplete="off"
            onSubmit={this.onSubmitForm}
          >
            <div className="row">
              <div className="col-12">
                <h1 className="text-center my-4">
                  Edit User : {firstName} {lastName}
                </h1>
              </div>
              {errorMessage && (
                <div className="col-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              )}
            </div>
            <div className="container boxWhite">
              <ItemText
                label="First Name"
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                placeholder="Enter first name ..."
                onChange={this.onChange}
                errors={errors.firstName}
              />
              <ItemText
                label="Last Name"
                id="lastName"
                name="lastName"
                placeholder="Enter last name ..."
                value={lastName}
                type="text"
                onChange={this.onChange}
                errors={errors.lastName}
              />
              <ItemText
                label="Phone"
                id="phone"
                name="phone"
                placeholder="Enter Phone Number ..."
                value={phone}
                type="text"
                onChange={this.onChange}
                errors={errors.phone}
              />
              <ItemText
                label="Email"
                id="email"
                name="email"
                placeholder="Enter Email Address ..."
                value={email}
                type="text"
                onChange={this.onChange}
                errors={errors.email}
              />
              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="accountLocked"
                >
                  Account Locked
                </label>
                <div className="col-sm-8">
                  <Toggle
                    id="accountLocked"
                    name="accountLocked"
                    defaultChecked={accountLocked}
                    onChange={this.hangleToggleChange}
                  />
                </div>
              </div>
              <h3>Permissions</h3>
              <FormInput
                type="radio"
                className="form-control"
                name="userRole"
                label="Account Role"
                onChange={this.onChange}
                placeholder=""
                value={userRole}
                valueOptions={userRoles}
              />
              {userRolePermissions.viewCustomers === "Selectable" && (
                <div className="row">
                  <div className="col-12">
                    <h3>Customer Permissions</h3>
                    <p>
                      Select Customer Account you wish this user to have access
                      to.
                    </p>

                    <table className="table table-sm small">
                      <thead className="thead-dark">
                        <tr>
                          <th>&nbsp;</th>
                          <th>Company Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map((customer, index) => {
                          if (customer.primaryAccount !== "1") {
                            let customerChecked = false;
                            if (
                              typeof customersChecked !== "undefined" &&
                              customersChecked !== "" &&
                              customersChecked !== "," &&
                              customersChecked !== null
                            ) {
                              if (
                                customersChecked.indexOf(
                                  "," + customer.id + ",",
                                ) !== -1
                              ) {
                                customerChecked = true;
                              }
                            }

                            return (
                              <tr key={index}>
                                <td className="text-right">
                                  <FormInputCheckboxElement
                                    id={"companyChecked" + index}
                                    name={"companyChecked" + index}
                                    value={customer.id}
                                    checked={customerChecked}
                                    handleCheckboxChange={() =>
                                      this.customerCheckboxClicked(customer.id)
                                    }
                                  />
                                </td>
                                <td>
                                  <label htmlFor={"companyChecked" + index}>
                                    {" "}
                                    {customer.name}{" "}
                                  </label>
                                  {typeof customersChecked !== "undefined" &&
                                    customersChecked !== "" &&
                                    customersChecked !== "," &&
                                    customersChecked !== null && (
                                      <Fragment>
                                        {customersChecked.indexOf(
                                          "," + customer.id + ",",
                                        ) !== -1 &&
                                          userRolePermissions.viewCustomers ===
                                            "Selectable" &&
                                          customer.folders.length > 0 && (
                                            <Fragment>
                                              <br />
                                              <b>Folders</b>
                                              <br />
                                              <table className="table table-sm table-striped table-hover small border">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th className="text-right">
                                                      &nbsp;
                                                    </th>
                                                    <th>Folder Name</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {customer.folders.map(
                                                    (folder, index2) => {
                                                      let folderChecked = false;
                                                      if (
                                                        typeof foldersChecked !==
                                                          "undefined" &&
                                                        foldersChecked !== "" &&
                                                        foldersChecked !==
                                                          "," &&
                                                        foldersChecked !== null
                                                      ) {
                                                        if (
                                                          foldersChecked.indexOf(
                                                            "," +
                                                              folder.id +
                                                              ",",
                                                          ) !== -1
                                                        ) {
                                                          folderChecked = true;
                                                        }
                                                      }
                                                      return (
                                                        <tr key={index2}>
                                                          <td
                                                            className="text-right"
                                                            style={{
                                                              minHeight: "auto",
                                                              height: "auto",
                                                            }}
                                                          >
                                                            <FormInputCheckboxElement
                                                              id={
                                                                "folderChecked" +
                                                                folder.id
                                                              }
                                                              name={
                                                                "folderChecked" +
                                                                folder.id
                                                              }
                                                              value={folder.id}
                                                              checked={
                                                                folderChecked
                                                              }
                                                              handleCheckboxChange={() =>
                                                                this.folderCheckboxClicked(
                                                                  folder.id,
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                          <td
                                                            style={{
                                                              minHeight: "auto",
                                                              height: "auto",
                                                            }}
                                                          >
                                                            <label
                                                              htmlFor={
                                                                "folderChecked" +
                                                                folder.id
                                                              }
                                                            >
                                                              {folder.fullPath}
                                                            </label>
                                                          </td>
                                                        </tr>
                                                      );
                                                    },
                                                  )}
                                                </tbody>
                                              </table>
                                            </Fragment>
                                          )}
                                      </Fragment>
                                    )}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <hr noshade="true" />
                </div>

                {errorMessage && (
                  <div className="col-12">
                    <div className="alert alert-danger">{errorMessage}</div>
                  </div>
                )}
                {isSaving === false && (
                  <Fragment>
                    <div className="col-sm-6 text-left mb-3">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => this.cancelEdit()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="col-sm-6 text-right mb-3">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => this.saveUser()}
                      >
                        SAVE USER
                      </button>
                    </div>
                  </Fragment>
                )}
                {isSaving === true && <Loading />}
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  userData: state.pages.userData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { saveUser, getUser }),
)(Client);
