import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  saveConfigSettings,
  getConfigSettings,
} from "../../../client/actions/apiActions";
import { API_URL } from "../../../config";
import store from "../../../client/store";

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: "",
      errorMessage: "",
    };
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveConfigSettings: PropTypes.func.isRequired,
    getConfigSettings: PropTypes.func.isRequired,
  };

  syncFTPFile = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    const token = store.getState().auth.token;
    const formData = new FormData();
    fetch(`${API_URL}/syncFtp`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          // ftp synced
          this.setState({
            successMessage: "FTP Synced successfully with no errors.",
          });
        } else {
          this.setState({ errorMessage: output.error });
        }
      });
  };

  backupDB = () => {
    this.setState({ successMessage: "", errorMessage: "" });
    const token = store.getState().auth.token;

    try {
      fetch(`${API_URL}/backup-db`, {
        method: "GET",
        headers: { Authorization: token },
      })
        .then((res) => res.json())
        .then((output) => {
          if (output.status === 1) {
            // ftp synced
            this.setState({
              successMessage: "DB Backup successfully with no errors.",
            });
          } else {
            this.setState({ errorMessage: output.error });
          }
        });
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  render() {
    const { successMessage, errorMessage } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="text-center my-4">Settings</h1>
            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}
            {errorMessage && (
              <div className="col-12">
                <div className="alert alert-danger">
                  <pre>{errorMessage}</pre>
                </div>
              </div>
            )}
            <Link to="/config/default-folders" className="btn btn-primary mr-1">
              Default Folders
            </Link>
            <Link to="/config/sort-folders" className="btn btn-primary mr-1">
              Sort Folders
            </Link>
            <Link to="/config/user-roles" className="btn btn-primary mr-1">
              User Roles
            </Link>
            <Link to="/config/email-templates" className="btn btn-primary mr-1">
              Email Templates
            </Link>
            <Link to="/config/settings" className="btn btn-primary mr-1">
              General Settings
            </Link>
            <button
              onClick={() => {
                this.syncFTPFile();
              }}
              className="btn btn-primary mr-1"
            >
              Sync FTP
            </button>
            <a
              href="/apicalls/backup-db"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mr-1"
            >
              Backup DB
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
  saveData: state.pages.saveData,
});

export default connect(mapStateToProps, {
  saveConfigSettings,
  getConfigSettings,
})(Config);
