import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AddEdit from "./AddEdit";
import { connect } from "react-redux";
import {
  saveSortingName,
  deleteSortingName,
} from "../../../../client/actions/apiActions";
import FolderList from "./FolderList";

const FolderSortList = (props) => {
  const { sortList } = props;
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editSortFolders, setEditSortFolders] = useState(false);
  const [editSortFolder, setEditSortFolder] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [folder, setFolder] = useState({});

  const addFolder = () => {
    setShowModal(true);
    setModalTitle("Add Folder");
  };

  const editFolder = (folderToEdit) => {
    setFolder(folderToEdit);
    setShowModal(true);
    setModalTitle("Edit Folder");
  };

  const deleteFolder = (id) => {
    props.deleteSortingName(id);
  };

  const onSave = (id, name) => {
    props.saveSortingName(id, name);
    setSaving(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setFolder({});
  };

  const editSort = (id) => {
    setEditSortFolders(true);
    setEditSortFolder(id);
  };

  useEffect(() => {
    if (props.data.pageName === "saveSortingName") {
      setSaving(false);
      closeModal();
      props.getSortList();
    }
    if (props.data.pageName === "deleteSortingName") {
      props.getSortList();
    }
  }, [props.data]);

  useEffect(() => {
    if (!sortList) {
      props.getSortList();
    }
  }, [sortList]);

  if (editSortFolders) {
    return (
      <FolderList
        sortFolderId={editSortFolder}
        getSortList={props.getSortList}
        setEditSortFolders={setEditSortFolders}
      />
    );
  }

  return (
    <>
      <button className="btn btn-sm btn-success mb-2" onClick={addFolder}>
        <i className="fa fa-plus" />
      </button>
      <table className="table table-sm table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {sortList?.map((folder, index) => {
            return (
              <tr key={index}>
                <td>{folder.name}</td>
                <td align="right">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      editSort(folder.id);
                    }}
                  >
                    <i className="fa fa-folder" />
                  </button>{" "}
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={() => {
                      editFolder(folder);
                    }}
                  >
                    <i className="fa fa-pencil-alt" />
                  </button>{" "}
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      deleteFolder(folder.id);
                    }}
                  >
                    <i className="fa fa-trash-alt" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal show={showModal} onClose={closeModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEdit folder={folder} onSave={onSave} saving={saving} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  saveSortingName,
  deleteSortingName,
})(FolderSortList);
