import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { login } from "../../../client/actions/auth";
import { Navigate } from "react-router-dom";
import { getConfigSettings } from "../../../client/actions/apiActions";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      username: "",
      password: "",
      loginDisclaimerMessage: "",
      errorMessage: "",
    };
  }

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  componentDidMount() {
    this.props.getConfigSettings();
    this.makeFocus();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (
        typeof this.props.errorData !== "undefined" &&
        this.props.errorData !== null &&
        this.props.errorData !== ""
      ) {
        if (typeof this.props.errorData.response.data.details !== "undefined") {
          this.setState({
            errorMessage: this.props.errorData.response.data.details,
          });
          this.makeFocus();
        }
      }
    }
  }

  makeFocus = () => {
    try {
      window.username.focus();
    } catch {}
  };
  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, errorMessage: "" });
  onSubmit = () => {
    const { username, password } = this.state;
    this.props.login(username, password);
  };
  onFormSubmit = (e) => {
    e.preventDefault();
  };
  render() {
    if (this.props.isAuthenticated) {
      return <Navigate to="/" />;
    }
    const { username, password, errorMessage } = this.state;
    const { loginDisclaimerMessage } = this.props.data;

    return (
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-6 m-auto">
            <div className="card card-body mt-5 text-center">
              <form onSubmit={this.onFormSubmit}>
                <img
                  src="/assets/images/logo.png"
                  border="0"
                  alt="CE-TECH"
                  width="180"
                  className="img-fluid"
                />
                <h2 className="text-center my-5">Document Portal Login</h2>
                {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )}
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label text-right">
                    Email Address
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      autoFocus="true"
                      onChange={this.onChange}
                      className="form-control form-control-sm"
                      autoComplete="section-red username"
                      value={username}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label text-right">
                    Password
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      name="password"
                      onChange={this.onChange}
                      className="form-control form-control-sm"
                      autoComplete="section-red current-password"
                      value={password}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-block btn-primary btn-sm"
                  onClick={this.onSubmit}
                >
                  <i className="fas fa-user-circle" /> LOG IN
                </button>
              </form>
            </div>
            <p className="text-center oswald">
              <Link to="/forgot-password">
                <i className="fas fa-unlock-alt" /> Forgot Password?
              </Link>
            </p>
            <br />
            <p
              style={{ fontSize: "10px" }}
              dangerouslySetInnerHTML={{ __html: loginDisclaimerMessage }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  data: state.pages.data,
  errorData: state.auth.errorData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { login, getConfigSettings }),
)(Login);
