import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfileNavigation from "./ProfileNavigation";
import "react-toggle/style.css";
import Toggle from "react-toggle";

import {
  saveProfileNotifications,
  getProfileSettings,
} from "../../../client/actions/apiActions";
import Loading from "../../layout/Loading";

class ProfileNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      successMessage: "",
      newFileNotifications: false,
      newMessageNotifications: false,
      newSignatureRequest: false,
    };
  }

  static propTypes = {
    profileData: PropTypes.object.isRequired,
    getProfileSettings: PropTypes.func.isRequired,
    saveProfileNotifications: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getProfileSettings();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { isLoaded } = this.state;
    if (isLoaded === false && typeof nextProps.profileData !== "undefined") {
      let notifications = nextProps.profileData.notifications;
      let newFileNotifications = false;
      let newMessageNotifications = false;
      let newSignatureRequest = false;

      if (typeof notifications !== "undefined" && notifications !== null) {
        if (typeof notifications.Notification_1 !== "undefined") {
          if (
            notifications.Notification_1 === 1 ||
            notifications.Notification_1 === "1"
          ) {
            newFileNotifications = true;
          } else {
            newFileNotifications = false;
          }
        }
        if (typeof notifications.Notification_2 !== "undefined") {
          if (
            notifications.Notification_2 === 1 ||
            notifications.Notification_2 === "1"
          ) {
            newMessageNotifications = true;
          } else {
            newMessageNotifications = false;
          }
        }
        if (typeof notifications.Notification_3 !== "undefined") {
          if (
            notifications.Notification_3 === 1 ||
            notifications.Notification_3 === "1"
          ) {
            newSignatureRequest = true;
          } else {
            newSignatureRequest = false;
          }
        }
      }
      this.setState({
        isLoaded: true,
        newFileNotifications: newFileNotifications,
        newMessageNotifications: newMessageNotifications,
        newSignatureRequest: newSignatureRequest,
      });
    } else if (typeof nextProps.profileData.success !== "undefined") {
      if (
        nextProps.profileData.success === "1" ||
        nextProps.profileData.success === 1
      ) {
        this.setState({ successMessage: nextProps.profileData.message });
      }
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: !e.target.value });
  saveProfileNotifications = () => {
    let newFileNotifications = this.state.newFileNotifications;
    let newMessageNotifications = this.state.newMessageNotifications;
    let newSignatureRequest = this.state.newSignatureRequest;

    if (newFileNotifications === true) {
      newFileNotifications = 1;
    } else {
      newFileNotifications = 0;
    }
    if (newMessageNotifications === true) {
      newMessageNotifications = 1;
    } else {
      newMessageNotifications = 0;
    }

    if (newSignatureRequest === true) {
      newSignatureRequest = 1;
    } else {
      newSignatureRequest = 0;
    }

    this.props.saveProfileNotifications(
      newFileNotifications,
      newMessageNotifications,
      newSignatureRequest,
    );
  };
  hangleToggleChange = (e) => {
    const {
      newFileNotifications,
      newMessageNotifications,
      newSignatureRequest,
    } = this.state;
    if (e.target.name === "newFileNotifications")
      this.setState({ newFileNotifications: !newFileNotifications });
    if (e.target.name === "newMessageNotifications")
      this.setState({ newMessageNotifications: !newMessageNotifications });
    if (e.target.name === "newSignatureRequest")
      this.setState({ newSignatureRequest: !newSignatureRequest });
  };
  render() {
    const {
      newFileNotifications,
      newMessageNotifications,
      newSignatureRequest,
      successMessage,
      errorMessage,
      isLoaded,
    } = this.state;
    if (isLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Edit Profile</h1>
            </div>
            <ProfileNavigation />
            <div className="col-8">
              {successMessage && (
                <div className="col-12">
                  <div className="alert alert-success">{successMessage}</div>
                </div>
              )}
              {errorMessage && (
                <div className="col-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              )}
              <h2>Configure Notifications</h2>
              <p>
                This screen lets you choose which notifications you wish to
                receive.
              </p>
              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="newFileNotifications"
                >
                  New File Notifications
                </label>
                <div className="col-sm-8">
                  <Toggle
                    id="newFileNotifications"
                    name="newFileNotifications"
                    defaultChecked={newFileNotifications}
                    onChange={this.hangleToggleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="newMessageNotifications"
                >
                  New Message Notifications
                </label>
                <div className="col-sm-8">
                  <Toggle
                    id="newMessageNotifications"
                    name="newMessageNotifications"
                    defaultChecked={newMessageNotifications}
                    onChange={this.hangleToggleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="newSignatureRequest"
                >
                  New Signature Request
                </label>
                <div className="col-sm-8">
                  <Toggle
                    id="newSignatureRequest"
                    name="newSignatureRequest"
                    defaultChecked={newSignatureRequest}
                    onChange={this.hangleToggleChange}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-secondary "
                onClick={() => this.saveProfileNotifications()}
              >
                <i className="fas fa-save" /> SAVE
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  profileData: state.pages.profileData,
});

export default connect(mapStateToProps, {
  saveProfileNotifications,
  getProfileSettings,
})(ProfileNotifications);
