import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveClient, getClient } from "../../../client/actions/apiActions";
import Loading from "../../layout/Loading";
import ItemText from "../../component/itemText";
import Toggle from "react-toggle";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../form/FormInput";
import { compose } from "redux";
import { withRouter } from "../../../utils";

const ClientEdit = (props) => {
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [active, setActive] = useState(false);
  const [errors, setErrors] = useState({});
  const [folderSort, setFolderSort] = useState("");
  const [folderSortList, setFolderSortList] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const saveClient = () => {
    setIsSaving(true);

    let noerror = true;
    let errors = {};
    if (companyName === "") {
      noerror = false;
      errors = {
        ...errors,
        companyName: 'You left the users "Company Name" blank',
      };
    }

    if (noerror === true) {
      props.saveClient(id, companyName, active, folderSort);
      props.router.navigate("/clients");
    } else {
      setIsSaving(false);
      setErrors(errors);
    }
  };

  const cancelEdit = () => {
    navigate("/clients");
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
  };

  const hangleToggleChange = (e) => {
    setActive(e.target.checked);
  };

  useEffect(() => {
    setLoading(true);
    try {
      props.getClient(id);
    } catch (e) {
      console.log("e", e);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (props.clientData.pageName === "getClient") {
      setCompanyName(props.clientData.companyName);
      setActive(props.clientData.active === "true");
      setFolderSort(props.clientData.folderSort);
      setFolderSortList(props.clientData.folderSortList);
      setLoading(false);
    }
  }, [props.clientData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <form
        name="editClientForm"
        id="editClientForm"
        autoComplete="off"
        onSubmit={onSubmitForm}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="text-center my-4">Edit Company : {companyName}</h1>
          </div>
        </div>

        <div className="container boxWhite">
          <ItemText
            label="Company Name"
            type="text"
            id="companyName"
            name="companyName"
            value={companyName}
            placeholder="Enter company name ..."
            onChange={(e) => setCompanyName(e.target.value)}
            errors={errors.companyName}
          />
          <FormInput
            label="Folder Sort"
            type="dropdown"
            id="folderSort"
            name="folderSort"
            value={folderSort}
            placeholder="Enter folder sort ..."
            onChange={(e) => setFolderSort(e.target.value)}
            errors={errors.folderSort}
            dropdown={folderSortList}
          />

          <div className="form-group row">
            <label
              className="col-sm-4 col-form-label"
              htmlFor="newSignatureRequest"
            >
              Active
            </label>
            <div className="col-sm-8">
              <Toggle
                id="active"
                name="active"
                defaultChecked={active}
                onChange={hangleToggleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <hr noshade="true" />
            </div>
            {isSaving === false && (
              <>
                <div className="col-sm-6 text-left mb-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => cancelEdit()}
                  >
                    Back
                  </button>
                </div>
                <div className="col-sm-6 text-right mb-3">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => saveClient()}
                  >
                    SAVE COMPANY
                  </button>
                </div>
              </>
            )}
            {isSaving === true && <Loading />}
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientData: state.pages.clientData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { saveClient, getClient }),
)(ClientEdit);
