import React from "react";

const FileList = (props) => {
  const {
    userInfo,
    userRole,
    files,
    moveFile,
    deleteFile,
    editFile,
    downloadFile,
    folders,
  } = props;

  if (folders.length === 0 && files.length === 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center">
          No files or folders found
        </td>
      </tr>
    );
  }

  return (
    <>
      {files.map((fileRow, index) => {
        return (
          <tr key={index} className="small">
            <td
              colSpan="2"
              nowrap="true"
              valign="middle"
              className="align-middle"
            >
              {(userRole.filesCanEdit === "1" || userInfo.userType === "A") && (
                <button
                  onClick={() => {
                    console.log("Edit Clicked");
                    editFile(fileRow.id, fileRow.documentName, fileRow.name);
                  }}
                  className="btn btn-success btn-sm m-1"
                >
                  <i className="fa fa-pencil-alt" title="Edit File Details" />
                </button>
              )}
              {(userRole.filesCanDelete === "1" ||
                userInfo.userType === "A") && (
                <button
                  className="btn btn-danger btn-sm m-1"
                  onClick={() => {
                    console.log("Delete Clicked");
                    deleteFile(fileRow.id);
                  }}
                >
                  <i className="fas fa-trash-alt" title="Delete File" />
                </button>
              )}
              {(userRole.filesCanMove === "1" || userInfo.userType === "A") && (
                <button
                  className="btn btn-primary btn-sm m-1"
                  onClick={() => moveFile(fileRow.id)}
                >
                  <i className="fas fa-archive" title="Move to File Cabinet" />
                </button>
              )}
              {(userRole.filesCanDownload === "1" ||
                userInfo.userType === "A") && (
                <button
                  className="btn btn-warning btn-sm m-1"
                  onClick={() => downloadFile(fileRow.id)}
                >
                  <i className="fas fa-download" title="Download File" />
                </button>
              )}
            </td>
            <td nowrap="true" valign="middle" className="align-middle">
              {fileRow.name}
            </td>
            <td nowrap="true" valign="middle" className="align-middle">
              {fileRow.documentName}
            </td>
            <td nowrap="true" valign="middle" className="align-middle">
              {fileRow.dateUploaded}
            </td>
            <td nowrap="true" valign="middle" className="align-middle">
              {fileRow.documentType}
            </td>
            <td nowrap="true" valign="middle" className="align-middle">
              {fileRow.documentSize}
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default FileList;
