import HeaderTemplate from "./components/layout/Header";
import FooterTemplate from "./components/layout/Footer";
import ReduxToastr from "react-redux-toastr";
import React from "react";
import { Outlet } from "react-router-dom";

const AppTemplate = ({ children }) => {
  return (
    <>
      <HeaderTemplate />
      {!children && <Outlet />}
      {children && children}
      <FooterTemplate />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </>
  );
};
export default AppTemplate;
