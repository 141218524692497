import React, {Component} from 'react';

class fancyBoolean extends Component {
    render() {
        if (this.props.value === 1 || this.props.value === '1') {
            return (
                <i className="fas fa-check-circle text-success" />
            )
        } else {
            return (
                <i className="fas fa-times-circle text-danger" />
            );
        }
    }
}

export default fancyBoolean