import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActiveClients } from "../../../client/actions/apiActions";
import Loading from "../../layout/Loading";
import { API_URL } from "../../../config";
import FormInputFileDragAndDrop from "../../form/FormInputFileDragAndDrop";
import FormInputText from "../../form/FormInputText";
import store from "../../../client/store";
import "react-confirm-alert/src/react-confirm-alert.css";
import gearsImage from "../../../assets/gears.gif";
import FormInput from "../../form/FormInput";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FileUpload: "",
      isLoaded: false,
      documents: [],
      successMessage: "",
      errorMessage: "",
      clientList: [],
      clientsList: [],
      usersList: [],
      parentUrl: "/documents",
      files: [],
      filesToUpload: [],
      fileList: [],
      pendingFiles: [],
      errorFiles: [],
      totalDocuments: 0,
      uploadWarning: "",
      uploadType: "",
      uploadForType: "",
      uploadFor: 0,
      saveMessage: "",
      saveMessageType: "",
    };
    this.selectedCheckboxes = new Set();
  }
  componentDidMount() {
    if (this.props.auth.user.user.userType === "A") {
      // change to load users
      this.props.getActiveClients();
    } else {
      this.setState({
        isLoaded: true,
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      isLoaded: true,
      clientsList: nextProps.clientList.clients,
      usersList: nextProps.clientList.users,
      //questionnaires: nextProps.documentList.questionnaires,
    });
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    clientList: PropTypes.object.isRequired,
  };

  onCancel = () => {
    const { parentUrl } = this.state;
    this.props.router.navigate(parentUrl);
  };
  onDeleteFile = (name) => {
    this.setState({
      fields: this.state.fields.map((s, index) => {
        if (this.state.fields[index].name === name) return { ...s, value: "" };
        return { ...s };
      }),
    });
  };
  onUploadFile = (uploadFiles) => {
    const files = Array.from(uploadFiles);
    const formData = new FormData();
    const token = store.getState().auth.token;
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    let uploadError = 0;

    formData.append("uploadType", "document");
    if (this.props.auth.user.user.userType === "A") {
      formData.append("uploadForType", this.state.uploadForType);
      formData.append("uploadFor", this.state.uploadFor);
    }
    if (
      this.props.auth.user.user.userType === "A" &&
      (this.state.uploadForType === "" || this.state.uploadFor === 0)
    ) {
      uploadError = 1;
    }

    if (uploadError !== 1) {
      files.forEach((file, i) => {
        if (this.state.fileList.includes(file.name)) {
          this.setState({
            uploadWarning: this.state.uploadWarning + " " + file.name,
          });
        } else {
          this.setState({
            pendingFiles: [...this.state.pendingFiles, file.name],
          });
          formData.append(i, file);
        }
      });

      // SET UPLOADING TO TRUE
      this.setState({ uploading: true });
      fetch(`${API_URL}/fileUpload`, {
        method: "POST",
        headers: { Authorization: token },
        body: formData,
      })
        .then((res) => res.json())
        .then((filesUploaded) => {
          if (typeof filesUploaded.documents !== "undefined") {
            filesUploaded.documents.map((file, index) => {
              if (file.status === 1) {
                this.setState({
                  files: [...this.state.files, file],
                  ["fileName" + index + "id"]: file.id,
                  fileList: [...this.state.fileList, file.fileName],
                  pendingFiles: this.state.pendingFiles.filter(
                    (filename) => filename !== file.fileName,
                  ),
                });
              } else {
                this.setState({
                  errorFiles: [
                    ...this.state.errorFiles,
                    { error: file.statusReason, name: file.fileName },
                  ],
                  fileList: [...this.state.fileList, file.fileName],
                  pendingFiles: this.state.pendingFiles.filter(
                    (filename) => filename !== file.fileName,
                  ),
                });
              }
              return true;
            });
          }
          this.setState({ totalDocuments: this.state.files.length });
        });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeClient = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      files: [],
      fileList: [],
      pendingFiles: [],
      errorFiles: [],
      uploadWarning: "",
      successMessage: "",
      saveMessageType: "",
      saveMessage: "",
      errorMessage: "",
    });
  };
  onChangeUploadType = (e) => {
    let uploadForType = e.target.value;
    let clientList = [];
    if (uploadForType === "Business") {
      clientList = this.state.clientsList;
    }
    if (uploadForType === "Personal") {
      clientList = this.state.usersList;
    }

    this.setState({
      uploadForType: uploadForType,
      clientList: clientList,
      files: [],
      fileList: [],
      pendingFiles: [],
      errorFiles: [],
      uploadWarning: "",
      uploadFor: 0,
      successMessage: "",
      saveMessageType: "",
      saveMessage: "",
      errorMessage: "",
    });
  };
  onSaveNames = () => {
    const { files } = this.state;
    const token = store.getState().auth.token;
    const formData = new FormData();
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    formData.append("updateType", "document");
    if (this.props.auth.user.user.userType === "A") {
      formData.append("uploadForType", this.state.uploadForType);
      formData.append("uploadFor", this.state.uploadFor);
    }
    files.forEach((file, index) => {
      formData.append("file" + index + "Name", this.state["fileName" + index]);
      formData.append(
        "file" + index + "ID",
        this.state["fileName" + index + "id"],
      );
      formData.append(
        "file" + index + "Description",
        this.state["fileDescription" + index],
      );
      formData.append("file" + index + "URL", file.fileName);
    });
    formData.append("filesLength", files.length);

    fetch(`${API_URL}/fileUpdate`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          this.setState({
            saveMessage: "Update Successful!",
            saveMessageType: 1,
          });

          const { parentUrl } = this.state;
          this.props.router.navigate(parentUrl);
        } else {
          this.setState({ saveMessage: "Update Failed!", saveMessageType: 0 });
        }
      });
  };
  render() {
    const { user } = this.props.auth;
    const {
      isLoaded,
      files,
      pendingFiles,
      errorFiles,
      totalDocuments,
      uploadWarning,
      saveMessage,
      saveMessageType,
      clientList,
      uploadFor,
      uploadForType,
    } = this.state;
    if (isLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">Upload a Document</h1>

              {user.user.userType === "A" && (
                <Fragment>
                  <h2>Upload Settings</h2>
                  <p className="text-danger">
                    Select the people you want to assign the file to and then
                    drag the files into the area provided.
                  </p>
                  <FormInput
                    type="radio"
                    name="uploadType"
                    label="Upload Type"
                    valueOptions={[
                      { name: "Individual User", value: "Personal" },
                      { name: "Business", value: "Business" },
                    ]}
                    onChange={this.onChangeUploadType}
                  />
                  <FormInput
                    type="dropdown"
                    name="uploadFor"
                    label="Upload is for"
                    dropdown={clientList}
                    value={uploadFor}
                    onChange={this.onChangeClient}
                  />
                </Fragment>
              )}
              {uploadForType !== "" && uploadFor !== "" && uploadFor !== 0 && (
                <FormInputFileDragAndDrop handleDrop={this.onUploadFile}>
                  {totalDocuments !== 0 && (
                    <div className="col-12 text-right">
                      Total Files: {totalDocuments}
                    </div>
                  )}
                  {saveMessageType === 0 && (
                    <div className="text-center alert alert-danger py-2 mb-2 rounded">
                      {saveMessage}
                    </div>
                  )}
                  {saveMessageType === 1 && (
                    <div className="text-center alert alert-success py-2 mb-2 rounded">
                      {saveMessage}
                    </div>
                  )}

                  <div className="DragDropFiles">
                    {uploadWarning && (
                      <div className="alert alert-danger py-2 mb-2 rounded">
                        A file with the same name has already been uploaded, if
                        the file you are uploading is a different file then
                        rename it and try again. : {uploadWarning}
                      </div>
                    )}
                    {files && (
                      <Fragment>
                        {files.map((file, index) => (
                          <div key={index} className="container">
                            <div className="form-group row border-bottom pb-2 bg-dark text-light">
                              <label
                                htmlFor="fileName0"
                                className="col-sm-12 m-0 pt-2 pb-2"
                              >
                                File Name: {file.fileName}
                              </label>
                            </div>
                            <input
                              type="hidden"
                              name={`fileName${index}id`}
                              value={this.state["fileName" + index + "id"]}
                            />
                            <FormInputText
                              name={`fileName${index}`}
                              value={this.state["fileName" + { index }]}
                              label="File Name"
                              placeholder="name ..."
                              helpText="Write short title of what the file is, example: 2018 personal tax return"
                              onChange={this.onChange}
                            />
                          </div>
                        ))}
                      </Fragment>
                    )}
                    {pendingFiles && (
                      <Fragment>
                        {pendingFiles.map((file, index) => (
                          <div key={index} className="container">
                            <div className="form-group row border-bottom pb-2 bg-dark text-light">
                              <label
                                htmlFor="fileName0"
                                className="col-sm-12 m-0 pt-2 pb-2"
                              >
                                File Name: {file}
                              </label>
                            </div>
                            <div className="col-12">
                              Status: <img src={gearsImage} alt="loading" />
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    )}
                    {errorFiles && (
                      <Fragment>
                        {errorFiles.map((file, index) => (
                          <div key={index} className="container">
                            <div className="form-group row border-bottom pb-2 bg-dark text-light">
                              <label
                                htmlFor="fileName0"
                                className="col-sm-12 m-0 pt-2 pb-2"
                              >
                                File Name: {file.name}
                              </label>
                            </div>
                            <div className="col-12 mb-3 rounded bg-warning text-light">
                              Status: {file.error}
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </div>
                </FormInputFileDragAndDrop>
              )}

              {totalDocuments > 0 && (
                <button
                  className="btn btn-success"
                  onClick={() => this.onSaveNames()}
                >
                  Save & Close
                </button>
              )}
              {totalDocuments === 0 && (
                <button
                  className="btn btn-secondary"
                  onClick={() => this.onCancel()}
                >
                  Back
                </button>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clientList: state.pages.clientList,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getActiveClients }),
)(Document);
