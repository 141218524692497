export const API_URL = getApiUrl();
export const appVersion = "1.0.5";

function getApiUrl() {
  // location is dev
  if (window.location.hostname === "localhost") {
    return "https://dev.ce-techdocuments.com/apicalls";
  }
  if (window.location.hostname.indexOf("dev") !== -1) {
    return "https://dev.ce-techdocuments.com/apicalls";
  }

  return "https://www.ce-techdocuments.com/apicalls";
}
