import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../layout/Loading";
import { getConfigSettings } from "../../client/actions/apiActions";
import { compose } from "redux";
import { withRouter } from "../../utils";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      scrollY: 0,
      scrollX: 0,
      scrollDiv: "",
      data: 0,
      dashboardContent: "",
      archivedBusinessMessage: "",
    };
  }
  componentDidMount() {
    this.props.getConfigSettings();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { initialDataLoaded } = this.state;
    if (initialDataLoaded === false && typeof nextProps.data !== "undefined") {
      this.setState({
        initialDataLoaded: true,
        loginDisclaimerMessage: nextProps.data.loginDisclaimerMessage,
        dashboardContent: nextProps.data.dashboardContent,
        archivedBusinessMessage: nextProps.data.archivedBusinessMessage,
      });
    }
  }

  render() {
    const { initialDataLoaded, dashboardContent, archivedBusinessMessage } =
      this.state;
    let companyIsActive = this.props?.auth?.user?.user?.companyIsActive;

    if (initialDataLoaded === true) {
      return (
        <div className="container my-4">
          <div className="row">
            {companyIsActive === 1 && (
              <div
                className="col-12"
                dangerouslySetInnerHTML={{ __html: dashboardContent }}
              />
            )}
            {companyIsActive === 0 && (
              <div
                className="col-12"
                dangerouslySetInnerHTML={{ __html: archivedBusinessMessage }}
              />
            )}
          </div>
        </div>
      );
    }

    return <Loading />;
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
  auth: state.auth,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { getConfigSettings }),
)(Dashboard);
