import React, {Component} from 'react';
import {connect} from "react-redux";
import { appVersion } from "../../config";

class Footer extends Component {
    render() {
        const { user } = this.props.auth;
        if (user !== null)
        {
            return (<footer className="page-footer noprint mt-4">
                <div className="container-fluid font-small bg-secondary text-light">
                    <div className="container">
                        <div className="row pt-3">
                            <div className="col-md-6">
                                <p>© CE-TECH. | All rights reserved</p>
                            </div>
                            <div className="col-md-6 text-right small text-middle">
                                <p>v. {appVersion}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>)
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect(mapStateToProps, {  })(Footer);