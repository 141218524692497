import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfileNavigation from "./ProfileNavigation";
import Loading from "../../layout/Loading";
import {
  saveProfileSettings,
  getProfileSettings,
} from "../../../client/actions/apiActions";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      successMessage: "",
      errorMessage: "",
      isLoaded: false,
    };
  }
  componentDidMount() {
    this.props.getProfileSettings();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { isLoaded } = this.state;
    if (isLoaded === false && typeof nextProps.profileData !== "undefined") {
      this.setState({
        firstName: nextProps.profileData.firstName,
        lastName: nextProps.profileData.lastName,
        phone: nextProps.profileData.phone,
        isLoaded: true,
      });
    } else if (typeof nextProps.profileData.success !== "undefined") {
      if (
        nextProps.profileData.success === "1" ||
        nextProps.profileData.success === 1
      ) {
        this.setState({ successMessage: nextProps.profileData.message });
      }
    } else if (typeof nextProps.profileData.error !== "undefined") {
      if (
        nextProps.profileData.error === "1" ||
        nextProps.profileData.error === 1
      ) {
        this.setState({ errorMessage: nextProps.profileData.message });
      }
    }
  }
  static propTypes = {
    profileData: PropTypes.object.isRequired,
    saveProfileSettings: PropTypes.func.isRequired,
    getProfileSettings: PropTypes.func.isRequired,
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveUserProfile = () => {
    const { firstName, lastName, phone } = this.state;
    this.props.saveProfileSettings(firstName, lastName, phone);
  };

  render() {
    const {
      firstName,
      lastName,
      phone,
      successMessage,
      errorMessage,
      isLoaded,
    } = this.state;
    if (isLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Edit Profile</h1>
            </div>
            <ProfileNavigation />
            <div className="col-8">
              <h2>Enter Profile Information</h2>
              {successMessage && (
                <div className="col-12">
                  <div className="alert alert-success">{successMessage}</div>
                </div>
              )}
              {errorMessage && (
                <div className="col-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              )}

              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="exampleFormControlInput1"
                >
                  First Name
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    onChange={this.onChange}
                    placeholder="enter first name ..."
                    value={firstName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Last Name
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    onChange={this.onChange}
                    placeholder="enter last name ..."
                    value={lastName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  className="col-sm-4 col-form-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Phone
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    onChange={this.onChange}
                    placeholder="enter payment subject ..."
                    value={phone}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-secondary "
                onClick={() => this.saveUserProfile()}
              >
                <i className="fas fa-save" /> SAVE
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  profileData: state.pages.profileData,
});

export default connect(mapStateToProps, {
  saveProfileSettings,
  getProfileSettings,
})(Profile);
