import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../client/actions/auth";
import logo from "../../assets/logo-small.png";
import { withRouter } from "../../utils";
import { compose } from "redux";

class Header extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";

    const guestLinks = (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item text-dark">
          <Link
            className="nav-link text-dark"
            onClick={this.toggleNavbar}
            to="/login"
          >
            <i className="fas fa-users" /> Login
          </Link>
        </li>
      </ul>
    );

    let firstName;
    let lastName;
    let companyIsActive;
    let userType;
    let adminLinks;
    let userLinks;
    let inactiveUserLinks;

    if (user !== null) {
      const userRole = user.userRole;
      const userInfo = user.user;

      adminLinks = (
        <ul className="navbar-nav mr-auto">
          <li className="nav-item text-dark">
            <Link
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              to="/"
            >
              <i className="fas fa-home" />
            </Link>
          </li>
          <li className="nav-item text-dark">
            <a
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              href="/documents"
            >
              <i className="fas fa-file-alt" /> Document Center
            </a>
          </li>
          <li className="nav-item text-dark">
            <Link
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              to="/clients"
            >
              <i className="fas fa-users" /> Businesses
            </Link>
          </li>
          <li className="nav-item text-dark">
            <Link
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              to="/config"
            >
              <i className="fas fa-cogs" /> Settings
            </Link>
          </li>
          <li className="nav-item text-dark">
            <a
              className="nav-link text-dark"
              onClick={this.props.logout}
              href="/"
            >
              <i className="fas fa-power-off" />
            </a>
          </li>
        </ul>
      );

      userLinks = (
        <ul className="navbar-nav mr-auto">
          <li className="nav-item text-dark">
            <Link
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              to="/"
            >
              <i className="fas fa-home" />
            </Link>
          </li>
          <li className="nav-item text-dark">
            <Link
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              to="/documents"
            >
              <i className="fas fa-file-alt" /> Document Center
            </Link>
          </li>
          {(userRole.usersCanInvite === "1" ||
            userRole.usersCanEdit === "1" ||
            userRole.usersCanDelete === "1" ||
            userInfo.userType === "A") && (
            <li className="nav-item text-dark">
              <Link
                className="nav-link text-dark"
                onClick={this.toggleNavbar}
                to="/clients"
              >
                <i className="fas fa-users" /> Users
              </Link>
            </li>
          )}
          <li className="nav-item text-dark">
            <a
              className="nav-link text-dark"
              onClick={this.props.logout}
              href="/"
            >
              <i className="fas fa-power-off" />
            </a>
          </li>
        </ul>
      );

      inactiveUserLinks = (
        <ul className="navbar-nav mr-auto">
          <li className="nav-item text-dark">
            <Link
              className="nav-link text-dark"
              onClick={this.toggleNavbar}
              to="/"
            >
              <i className="fas fa-home" />
            </Link>
          </li>
          <li className="nav-item text-dark">
            <a
              className="nav-link text-dark"
              onClick={this.props.logout}
              href="/"
            >
              <i className="fas fa-power-off" />
            </a>
          </li>
        </ul>
      );

      if (typeof this.props.auth.user.user !== "undefined") {
        if (typeof this.props.auth.user.user.firstName !== "undefined") {
          firstName = this.props.auth.user.user.firstName;
          lastName = this.props.auth.user.user.lastName;
          companyIsActive = this.props.auth.user.user.companyIsActive;
          userType = this.props.auth.user.user.userType;
        }
      }
    }

    return (
      <div className="container-fluid bg-light text-dark BorderShadow noprint border-bottom border-primary">
        {isAuthenticated && (
          <div className="container">
            <div className="row">
              <div className="col-6">
                <img
                  src={logo}
                  alt="Company Logo"
                  style={{ width: "auto", height: "60px" }}
                  border="0"
                />
              </div>
              <div className="col-6 text-right">
                <div className="nomobile rounded-bottom bg-dark text-light">
                  Logged In as {firstName} {lastName} :
                  <Link className="mx-3 text-white" to="/profile/edit">
                    <i className="fas fa-users" /> Profile
                  </Link>
                  <button
                    className="mx-3 text-white btn btn-link"
                    onClick={this.props.logout}
                  >
                    <i className="fas fa-power-off text-white " />
                  </button>
                </div>
                <div className="mobileOnly rounded-bottom bg-dark text-light">
                  <Link className="mx-3 text-white" to="/profile/edit">
                    <i className="fas fa-users" />
                  </Link>
                  <button
                    className="mx-3 text-white btn btn-link"
                    onClick={this.props.logout}
                  >
                    <i className="fas fa-power-off text-white " />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <button
              onClick={this.toggleNavbar}
              className={`${classTwo}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon5">
                <i className="fas fa-bars" />
              </span>
            </button>
            <div className={`${classOne}`} id="navbarSupportedContent">
              {isAuthenticated === true && (
                <>
                  {(companyIsActive === "1" || companyIsActive === 1) && (
                    <>
                      {userType === "A" && <>{adminLinks}</>}
                      {userType === "U" && <>{userLinks}</>}
                    </>
                  )}
                  {(companyIsActive === "0" || companyIsActive === 0) && (
                    <>{inactiveUserLinks}</>
                  )}
                </>
              )}
              {isAuthenticated === false && <>{guestLinks}</>}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { logout }),
)(Header);
