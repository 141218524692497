import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  searchCompanies,
  getAssociations,
  deleteAssociation,
  saveAssociation,
} from "../../../client/actions/apiActions";

import Loading from "../../layout/Loading";
import FormInput from "../../form/FormInput";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class Client_Associations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSaving: false,
      successMessage: "",
      errorMessage: "",
      user: {},
      search: "",
      associations: {},
      searchResults: {},
      errors: {},
    };
  }
  componentDidMount() {
    try {
      let { id } = this.props.router.params;
      this.props.getAssociations(id);
    } catch (e) {
      console.log("e", e);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (prevProps.companies !== this.props.companies) {
        const { clients } = this.props.companies;
        this.setState({
          searchResults: clients,
        });
      }
      if (prevProps.associations !== this.props.associations) {
        const { associations, usersFirstName, usersLastName } =
          this.props.associations;
        this.setState({
          isLoading: false,
          firstName: usersFirstName,
          lastName: usersLastName,
          associations: associations,
        });
      }
      if (this.props.success === 0) {
        this.setState({ errorMessage: this.props.message });
      }
    }
  }

  static propTypes = {
    searchCompanies: PropTypes.func.isRequired,
    getAssociations: PropTypes.func.isRequired,
    deleteAssociation: PropTypes.func.isRequired,
    saveAssociation: PropTypes.func.isRequired,
  };
  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
  onSubmitForm = (e) => {
    e.preventDefault();
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "search") {
      this.props.searchCompanies(e.target.value);
    }
  };
  onAddAssociation = (associationId, associationType) => {
    let { id } = this.props.router.params;
    this.props.saveAssociation(id, associationId, associationType);
  };
  onDeleteAssociation = (associationId) => {
    let { id } = this.props.router.params;
    this.props.deleteAssociation(id, associationId);
  };
  cancelEdit = () => {
    this.props.router.navigate("/clients");
  };

  render() {
    const { isLoading, errors, errorMessage } = this.state;

    if (isLoading === false) {
      const { firstName, lastName, search, associations, searchResults } =
        this.state;
      return (
        <div className="container">
          <form
            name="editClientForm"
            id="editClientForm"
            autoComplete="off"
            onSubmit={this.onSubmitForm}
          >
            <div className="row">
              <div className="col-12">
                <h1 className="text-center my-4">
                  Edit User : {firstName} {lastName}
                </h1>
              </div>
              {errorMessage && (
                <div className="col-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              )}
            </div>

            <div className="container">
              <div className="row">
                <div className="col-md-6 m-auto">
                  <div className="card card-body text-center">
                    <h2>User Associations</h2>
                    <FormInput
                      name="search"
                      label="Search"
                      value={search}
                      placeholder="search term"
                      type="text"
                      onChange={this.onChange}
                      error={errors.search}
                    />

                    {this.isEmpty(searchResults) !== true && (
                      <div className="row">
                        <div className="col-12">
                          <h2>Results</h2>
                          <table className="table table-sm table-hover table-striped">
                            <thead>
                              <tr className="thead-dark">
                                <th className="text-left">Name</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {searchResults.map((result, index) => (
                                <tr key={index}>
                                  <td className="text-left align-middle">
                                    {result.type === "Company" && (
                                      <i className="fas fa-building" />
                                    )}
                                    {result.type === "User" && (
                                      <i className="fas fa-user" />
                                    )}{" "}
                                    {result.name}
                                  </td>
                                  <td className="text-right align-middle">
                                    <button
                                      onClick={() =>
                                        this.onAddAssociation(
                                          result.id,
                                          result.type,
                                        )
                                      }
                                      className="btn btn-sm btn-success"
                                    >
                                      <i className="fas fa-user-plus" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {this.isEmpty(associations) !== true && (
                      <div className="row">
                        <div className="col-12">
                          <h3>Existing Associations</h3>
                        </div>
                        <div className="col-12">
                          <table className="table table-sm table-hover table-striped">
                            <thead>
                              <tr className="thead-dark">
                                <th className="text-left">Name</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {associations.map((result, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-left align-middle">
                                      {result.type === "Company" && (
                                        <i className="fas fa-building" />
                                      )}
                                      {result.type === "User" && (
                                        <i className="fas fa-user" />
                                      )}{" "}
                                      {result.name}
                                    </td>
                                    <td className="text-right align-middle">
                                      <button
                                        onClick={() =>
                                          this.onDeleteAssociation(result.id)
                                        }
                                        className="btn btn-sm btn-danger"
                                      >
                                        <i className="fas fa-trash-alt" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12">
                        <hr noshade="true" />
                      </div>
                      <div className="col-sm-6 text-left mb-3">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => this.cancelEdit()}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  associations: state.pages.associations,
  companies: state.pages.companies,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    searchCompanies,
    getAssociations,
    saveAssociation,
    deleteAssociation,
  }),
)(Client_Associations);
