import React from "react";

const ClientList = ({ clients }) => {
  return (
    <div className="col-12">
      <h3>Client List</h3>
      <p>Select the client to view.</p>
      <div className="table-responsive">
        <table className="table table-sm table-hover table-striped">
          <thead>
            <tr className="thead-dark">
              <th>Client Name</th>
            </tr>
          </thead>
          <tbody>
            {typeof clients !== "undefined" && (
              <>
                {clients.length > 0 &&
                  clients.map((client, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a href={`/documents/${client.id}`}>
                            <i className="fas fa-folder" /> {client.name}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientList;
