import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormInput from "../../form/FormInput";
import {
  saveConfigSettings,
  getConfigSettings,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import { Link } from "react-router-dom";

class Config_Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      fromName: "",
      fromEmail: "",
      paymentSubject: "",
      loginDisclaimerMessage: "",
      dashboardContent: "",
      archivedBusinessMessage: "",
      successMessage: "",
      error: {},
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveConfigSettings: PropTypes.func.isRequired,
    getConfigSettings: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getConfigSettings();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { initialDataLoaded } = this.state;
    if (initialDataLoaded === false && typeof nextProps.data !== "undefined") {
      this.setState({
        initialDataLoaded: true,
        loginDisclaimerMessage: nextProps.data.loginDisclaimerMessage,
        dashboardContent: nextProps.data.dashboardContent,
        archivedBusinessMessage: nextProps.data.archivedBusinessMessage,
      });
    } else if (
      nextProps.saveData.success === "1" ||
      nextProps.saveData.success === 1
    ) {
      window.scrollTo(0, 0);
      this.setState({ successMessage: nextProps.saveData.message });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveConfigSettings = () => {
    const {
      loginDisclaimerMessage,
      dashboardContent,
      archivedBusinessMessage,
    } = this.state;
    this.props.saveConfigSettings(
      loginDisclaimerMessage,
      dashboardContent,
      archivedBusinessMessage,
    );
  };
  handleEditorChange = (e) => {
    let loginDisclaimerMessage = e.target.getContent();
    this.setState({ loginDisclaimerMessage: loginDisclaimerMessage });
  };
  handleEditorDashboardChange = (e) => {
    let dashboardContent = e.target.getContent();
    this.setState({ dashboardContent: dashboardContent });
  };
  handleEditorArchivedChange = (e) => {
    let archivedBusinessMessage = e.target.getContent();
    this.setState({ archivedBusinessMessage: archivedBusinessMessage });
  };
  render() {
    const {
      initialDataLoaded,
      successMessage,
      loginDisclaimerMessage,
      dashboardContent,
      error,
      archivedBusinessMessage,
    } = this.state;
    if (initialDataLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">Config Settings</h1>
            </div>

            <div className="col-12 mb-2">
              <Link to="/config" className="btn btn-sm btn-secondary">
                Back
              </Link>
            </div>
            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}

            <div className="container">
              <div className="row boxWhite">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Dashboard Introduction
                    </label>
                    <FormInput
                      type="editor"
                      className="form-control"
                      name="dashboardContent"
                      handleEditorChange={this.handleEditorDashboardChange}
                      placeholder=""
                      value={dashboardContent}
                      error={error.dashboardContent}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Log In Disclaimer Message
                    </label>
                    <FormInput
                      type="editor"
                      className="form-control"
                      name="loginDisclaimerMessage"
                      handleEditorChange={this.handleEditorChange}
                      placeholder=""
                      value={loginDisclaimerMessage}
                      error={error.loginDisclaimerMessage}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Archived Business Login Message
                    </label>
                    <FormInput
                      type="editor"
                      className="form-control"
                      name="archivedBusinessMessage"
                      handleEditorChange={this.handleEditorArchivedChange}
                      placeholder=""
                      value={archivedBusinessMessage}
                      error={error.archivedBusinessMessage}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <Link
                    to="/config/email-templates"
                    className="btn btn-dark mr-1"
                  >
                    CANCEL
                  </Link>
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => this.saveConfigSettings()}
                  >
                    SAVE SETTINGS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
  saveData: state.pages.saveData,
});

export default connect(mapStateToProps, {
  saveConfigSettings,
  getConfigSettings,
})(Config_Settings);
