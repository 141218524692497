import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import TabBusinessesClients from "./TabBusinessesClients";
import Loading from "../../layout/Loading";

const TabBusinesses = (props) => {
  const { clientList, setSuccessMessage, userInfo, userRole } = props;
  if (!userRole || !clientList) {
    return <Loading />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <h2 className="my-2 ml-2">Users by Business</h2>
          </div>
          <div className="col-12 col-sm-6 text-right">
            {(userRole.companyCanAdd === "1" || userInfo.userType === "A") && (
              <Link className="btn btn-warning m-2" to="/clients/add">
                <i className="fas fa-building" /> Add New Business
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-sm table-striped">
          <tbody>
            {clientList.map((client) => {
              return (
                <TabBusinessesClients
                  client={client}
                  key={client.id}
                  setSuccessMessage={setSuccessMessage}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.pages.clientList.userRole,
  userInfo: state.pages.clientList.user,
  data: state,
});

export default connect(mapStateToProps, {})(TabBusinesses);
