import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  saveEmailTemplate,
  getEmailTemplate,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import FormInput from "../../form/FormInput";
import { compose } from "redux";
import { withRouter } from "../../../utils";

class Config_Email_Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDataLoaded: false,
      name: "",
      fromName: "",
      fromEmail: "",
      message: "",
      subject: "",
      successMessage: "",
      error: {},
      pageType: "",
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveEmailTemplate: PropTypes.func.isRequired,
    getEmailTemplate: PropTypes.func.isRequired,
  };
  componentDidMount() {
    let { id } = this.props.router.params;
    if (typeof id !== "undefined" && id !== "0") {
      this.props.getEmailTemplate(id);
      this.setState({ pageType: "Edit" });
    } else {
      this.setState({ pageType: "Add", data: {} });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { initialDataLoaded } = this.state;
    if (initialDataLoaded === false && typeof nextProps.data !== "undefined") {
      this.setState({
        initialDataLoaded: true,
        data: nextProps.data,

        name: nextProps.data.name,
        fromName: nextProps.data.fromName,
        fromEmail: nextProps.data.fromEmail,
        subject: nextProps.data.subject,
        message: nextProps.data.message,
      });
    } else if (
      nextProps.saveData.success === "1" ||
      nextProps.saveData.success === 1
    ) {
      this.setState({ successMessage: nextProps.saveData.message });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onCancel = () => {
    this.props.router.navigate("/config/email-templates");
  };
  onEditorChange = (e) => {
    let message = e.target.getContent();
    this.setState({ message: message });
  };
  onSave = () => {
    let { id } = this.props.router.params;
    const { message, subject, fromName, fromEmail, name } = this.state;
    this.props.saveEmailTemplate(
      id,
      message,
      subject,
      fromName,
      fromEmail,
      name,
    );
    this.props.router.navigate("/config/email-templates");
  };
  render() {
    const {
      successMessage,
      data,
      error,
      message,
      subject,
      fromName,
      fromEmail,
      name,
      pageType,
    } = this.state;

    if (typeof data !== "undefined") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">{pageType} Email Template</h1>
            </div>
            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}

            <div className="container">
              <div className="row boxWhite">
                <div className="col-12">
                  <FormInput
                    type="input"
                    className="form-control"
                    name="name"
                    label="Template Name"
                    onChange={this.onChange}
                    placeholder=""
                    value={name}
                    error={error.name}
                  />
                  <FormInput
                    type="input"
                    className="form-control"
                    name="fromName"
                    label="From Name"
                    onChange={this.onChange}
                    placeholder=""
                    value={fromName}
                    error={error.fromName}
                  />
                  <FormInput
                    type="input"
                    className="form-control"
                    name="fromEmail"
                    label="From Email"
                    onChange={this.onChange}
                    placeholder=""
                    value={fromEmail}
                    error={error.fromEmail}
                  />
                  <FormInput
                    type="input"
                    className="form-control"
                    name="subject"
                    label="Subject"
                    onChange={this.onChange}
                    placeholder=""
                    value={subject}
                    error={error.subject}
                  />
                  <FormInput
                    type="editor"
                    className="form-control"
                    name="message"
                    label="Email Message"
                    handleEditorChange={this.onEditorChange}
                    placeholder=""
                    value={message}
                    error={error.message}
                  />
                </div>

                <div className="col-sm-6  mb-3">
                  <button
                    type="submit"
                    className="btn btn-dark"
                    onClick={() => this.onCancel()}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-sm-6 text-right mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => this.onSave()}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    saveEmailTemplate,
    getEmailTemplate,
  }),
)(Config_Email_Template);
