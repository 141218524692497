import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "../../../utils";
import {
  getDocuments,
  deleteFile,
  archiveFile,
  moveFile,
  getDocumentClients,
  getClientFolders,
  getClientFiles,
  deleteFolder,
} from "../../../client/actions/apiActions";
import { API_URL } from "../../../config";
import DocumentCenterBreadCrumb from "./DocumentCenterBreadCrumb";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Loading from "../../layout/Loading";
import FormInputText from "../../form/FormInputText";
import store from "../../../client/store";
import FormInputFileDragAndDrop from "../../form/FormInputFileDragAndDrop";
import gearsImage from "../../../assets/gears.gif";
import FormInput from "../../form/FormInput";
import DocumentCenterSearchResults from "./DocumentCenter_SearchResults";

import { toastr } from "react-redux-toastr";
import "react-confirm-alert/src/react-confirm-alert.css";
import ClientList from "./ClientList";
import DocumentList from "./DocumentList";
import { compose } from "redux";

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFiles: false,
      addFolder: false,
      editFile: false,
      isLoaded: false,
      showSearchResults: false,
      documents: [],
      clients: {},
      folders: [],
      folderLocation: [],
      folderLocationList: [],
      files: [],
      addFolderType: 0,
      tmpSearchTerm: "",
      breadcrumb: [],

      parentFolderPath: "/",

      successMessage: "",
      folderError: "",
      errorMessage: "",
      clientId: 0,
      folder: "",
      clientName: "",

      // file edit
      fileName: "",

      sortDir: "",
      sortField: "",
      showType: 1,

      FileUpload: "",
      clientList: [],
      clientsList: [],
      usersList: [],
      filesToUpload: [],
      fileList: [],
      uploadedFiles: [],
      pendingFiles: [],
      errorFiles: [],
      totalDocuments: 0,
      uploadWarning: "",
      uploadType: "",
      uploadForType: "",
      uploadFor: 0,
      saveMessage: "",
      saveMessageType: "",

      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
    };
    this.selectedCheckboxes = new Set();
  }

  componentDidMount() {
    let {
      clientId,
      folderId,
      folderName1,
      folderName2,
      folderName3,
      folderName4,
      folderName5,
      folderName6,
      folderName7,
      folderName8,
      folderName9,
      folderName10,
      folderName11,
      folderName12,
    } = this.props.router.params;

    if (typeof clientId === "undefined") clientId = "";
    if (typeof folderId === "undefined") folderId = "";
    if (typeof folderName1 === "undefined") folderName1 = "";
    if (typeof folderName2 === "undefined") folderName2 = "";
    if (typeof folderName3 === "undefined") folderName3 = "";
    if (typeof folderName4 === "undefined") folderName4 = "";
    if (typeof folderName5 === "undefined") folderName5 = "";
    if (typeof folderName6 === "undefined") folderName6 = "";
    if (typeof folderName7 === "undefined") folderName7 = "";
    if (typeof folderName8 === "undefined") folderName8 = "";
    if (typeof folderName9 === "undefined") folderName9 = "";
    if (typeof folderName10 === "undefined") folderName10 = "";
    if (typeof folderName11 === "undefined") folderName11 = "";
    if (typeof folderName12 === "undefined") folderName12 = "";
    if (folderId !== "" && clientId !== "") {
      let fullPath = "";
      if (folderName1 !== "") fullPath += "/" + folderName1;
      if (folderName2 !== "") fullPath += "/" + folderName2;
      if (folderName3 !== "") fullPath += "/" + folderName3;
      if (folderName4 !== "") fullPath += "/" + folderName4;
      if (folderName5 !== "") fullPath += "/" + folderName5;
      if (folderName6 !== "") fullPath += "/" + folderName6;
      if (folderName7 !== "") fullPath += "/" + folderName7;
      if (folderName8 !== "") fullPath += "/" + folderName8;
      if (folderName9 !== "") fullPath += "/" + folderName9;
      if (folderName10 !== "") fullPath += "/" + folderName10;
      if (folderName11 !== "") fullPath += "/" + folderName11;
      if (folderName12 !== "") fullPath += "/" + folderName12;
      this.cancelEdit();
      this.props.getClientFiles(
        clientId,
        fullPath,
        this.state.sortDir,
        this.state.sortField,
        folderId,
      );
    } else if (clientId !== "" && folderId === "") {
      this.selectClient(clientId);
    } else {
      this.props.getDocumentClients();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (
        this.props.dataList.displayType === "Clients" ||
        this.props.clientList.displayType === "Clients"
      ) {
        if (this.state.clients !== this.props.clientList.clients) {
          this.setState({
            isLoaded: true,
            clients: this.props.clientList.clients,
            clientId: 0,
            folderId: "",
            parentFolderPath: "/",
            editFile: false,
            addFolder: false,
            editFolder: false,
            uploadFiles: false,
            totalDocuments: 0,
            saveMessage: "",
          });
        }
      }
      if (this.props.dataList.displayType === "ClientFolders") {
        if (
          this.state.folders !== this.props.dataList.folders &&
          typeof this.props.dataList.folders !== "undefined"
        ) {
          let tfiles = [];
          if (this.props.dataList.files.length > 0) {
            tfiles = this.props.dataList.files;
          }
          // let folderSortList = this.props.dataList.folderSortList.filter(
          //   (item) => item.parentFolder === "0",
          // );
          // this.props.dataList.folders

          this.setState({
            // folderSortList: folderSortList,
            clientId: this.props.dataList.clientId,
            folderId: "",
            clientName: this.props.dataList.companyName,
            folders: this.props.dataList.folders,
            folderTypes: this.props.dataList.folderTypes,
            folderType: this.props.dataList.folderType,
            files: tfiles,
            parentFolderPath: "/",
            editFile: false,
            addFolder: false,
            editFolder: false,
            uploadFiles: false,
            saveMessage: "",
            isLoaded: true,
          });
        }
      }
      if (this.props.dataList.displayType === "ClientFiles") {
        if (
          this.state.folders !== this.props.dataList.folders &&
          typeof this.props.dataList.folders !== "undefined"
        ) {
          // let folderSortList = this.props.dataList.folderSortList.filter(
          //   (item) => item.parentFolder === this.props.dataList.folderId,
          // );
          // this.props.dataList.folders

          this.setState({
            // folderSortList: folderSortList,
            clientId: this.props.dataList.clientId,
            folderId: this.props.dataList.folderId,
            clientName: this.props.dataList.companyName,
            folders: this.props.dataList.folders,
            files: this.props.dataList.files,
            folderType: this.props.dataList.folderType,
            parentFolderPath: this.props.dataList.parentFolderPath,
            breadcrumb: this.props.dataList.breadcrumb,
            saveMessage: "",
            isLoaded: true,
            folderLocationList: this.props.dataList.clientFolders,
          });
        }
      }
      if (this.props.dataList.displayType === "deleteFile") {
        if (
          this.state.parentFolderPath !== "/" &&
          this.state.parentFolderPath !== ""
        ) {
          this.selectFolder(
            this.state.parentFolderPath,
            this.state.sortDir,
            this.state.sortField,
            this.state.folderId,
          );
        } else {
          this.selectClient(
            this.state.clientId,
            this.state.sortDir,
            this.state.sortField,
          );
        }
      }
      if (this.props.dataList.displayType === "moveFile") {
        if (
          this.props.dataList.status === "1" ||
          this.props.dataList.status === 1
        ) {
          this.showToastr("success", "File Moved", `File has been moved`);
        }
        if (
          this.props.dataList.status === "0" &&
          this.props.dataList.status === 0
        ) {
          this.showToastr("error", "File Move Failed", `File moved failed`);
        }
        if (
          this.state.parentFolderPath !== "/" &&
          this.state.parentFolderPath !== ""
        ) {
          this.selectFolder(
            this.state.parentFolderPath,
            this.state.sortDir,
            this.state.sortField,
            this.state.folderId,
          );
        } else {
          this.selectClient(
            this.state.clientId,
            this.state.sortDir,
            this.state.sortField,
          );
        }
      }
      if (this.props.dataList.displayType === "errorFile") {
        this.setState({
          fileError: this.props.dataList.message,
        });
      }
    }
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    documentList: PropTypes.object.isRequired,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectClient = (clientId, sortDir, sortField) => {
    this.cancelEdit();
    this.props.getClientFolders(clientId, sortDir, sortField);
  };

  downloadFile = (fileId) => {
    window.open(API_URL + "/viewFile/" + fileId);
  };

  editFile = (fileId, fileUrl, fileName) => {
    this.setState({
      fileId,
      fileName,
      fileUrl,
      editFile: true,
    });
  };

  showToastr = (type, title, message) => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position,
    };
    const toastrInstance =
      type === "info"
        ? toastr.info
        : type === "warning"
        ? toastr.warning
        : type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance(title, message, options);
  };

  confirmMoveFile = (fileId) => {
    const { folderLocation, clientId } = this.state;
    this.props.moveFile(clientId, fileId, folderLocation);
  };

  moveFile = (fileId) => {
    let { folderLocationList } = this.state;
    folderLocationList = folderLocationList.map((folder) => {
      return { ...folder, name: folder.folderName, value: folder.id };
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="card-body">
              <h1>Move File?</h1>
              <p>Where do you want to move this file?</p>
              <FormInput
                showOneColumn="true"
                type="dropdown"
                className="form-control form-control-sm"
                id="folderLocation"
                name="folderLocation"
                label="New Location"
                onChange={this.onChange}
                placeholder=""
                dropdown={folderLocationList}
              />
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-6">
                  <button className="btn btn-secondary" onClick={onClose}>
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.confirmMoveFile(fileId);
                      onClose();
                    }}
                  >
                    Move File
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  deleteFile = (fileId) => {
    confirmAlert({
      title: "Confirm Delete",
      message:
        "Are you sure you want to delete this file. You can not restore later on.",
      buttons: [
        {
          label: "Yes, Delete",
          onClick: () => {
            this.props.deleteFile(fileId, this.state.clientId);
          },
        },
        {
          label: "No, Do Not Delete",
        },
      ],
    });
  };

  cancelEdit = () => {
    this.setState({
      folderError: "",
      folderId: "",
      folderName: "",
      folderPath: "",
      editFile: false,
      addFolder: false,
      editFolder: false,
      uploadFiles: false,
      totalDocuments: 0,
    });
  };

  saveFileName = () => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    formData.append("clientId", this.state.clientId);
    formData.append("fileId", this.state.fileId);
    formData.append("fileName", this.state.fileName);

    fetch(`${API_URL}/fileRename`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          this.setState({ fileName: "", fileUrl: "", editFile: false });
          if (
            this.state.parentFolderPath !== "/" &&
            this.state.parentFolderPath !== ""
          ) {
            this.selectFolder(
              this.state.parentFolderPath,
              this.state.sortDir,
              this.state.sortField,
              this.state.folderId,
            );
          } else {
            this.selectClient(this.state.clientId);
          }
        } else {
          this.setState({ saveMessage: "Update Failed!", saveMessageType: 0 });
        }
      });
  };

  selectFolder = (fullPath, sortDir, sortField, folderId) => {
    this.cancelEdit();
    this.props.getClientFiles(
      this.state.clientId,
      fullPath,
      sortDir,
      sortField,
      folderId,
    );
  };

  editFolder = (folderId, folderPath, folderName, folderType) => {
    this.setState({
      editFolder: true,
      folderId: folderId,
      folderName: folderName,
      folderOriginalName: folderName,
      folderPath: folderPath,
      folderType: folderType,
    });
  };

  deleteFolder = (folderId) => {
    confirmAlert({
      title: "Confirm Delete",
      message:
        "Are you sure you want to delete this folder. You can not restore later on, and all files in this folder will be deleted.",
      buttons: [
        {
          label: "Yes, Delete",
          onClick: () => this.onDeleteFolder(this.state.clientId, folderId),
        },
        {
          label: "No, Do Not Delete",
        },
      ],
    });
  };

  handleShowAddFolder = () => {
    this.setState({
      addFolder: true,
      folderId: "",
      folderName: "",
      folderType: "",
    });
  };

  // onAddFolder = () => {
  //   let showType = 1;
  //   let folderType = 2;
  //   const formData = new FormData();
  //   formData.append("clientId", this.state.clientId);
  //   formData.append("fullPath", this.state.parentFolderPath);
  //   const token = store.getState().auth.token;
  //   fetch(`${API_URL}/checkFolder`, {
  //     method: "POST",
  //     headers: { Authorization: token },
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((folderCheck) => {
  //       if (
  //         folderCheck.folderType === 1 ||
  //         folderCheck.folderType === 3 ||
  //         folderCheck.folderType === "1" ||
  //         folderCheck.folderType === "3"
  //       ) {
  //         // hide type
  //         showType = 0;
  //         folderType = folderCheck.folderType;
  //       } else {
  //       }
  //
  //       this.setState({
  //         addFolder: true,
  //         folderId: "",
  //         addFolderType: folderType,
  //         folderType: folderType,
  //         showType: showType,
  //       });
  //     });
  // };

  cancelAddFolder = () => {
    this.setState({
      editFile: false,
      addFolder: false,
      editFolder: false,
      uploadFiles: false,
      totalDocuments: 0,
    });
  };

  saveFolderName = () => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    formData.append("clientId", this.state.clientId);
    formData.append("folderId", this.state.folderId);
    formData.append("folderPath", this.state.folderPath);
    formData.append("fullPath", this.state.parentFolderPath);
    formData.append("folderName", this.state.folderName);
    formData.append("folderType", this.state.folderType);
    formData.append("folderOriginalName", this.state.folderOriginalName);

    fetch(`${API_URL}/folderCreate`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          this.setState({
            folderError: "",
            folderId: "",
            folderName: "",
            folderPath: "",
            folderType: "",
            addFolder: false,
            editFolder: false,
          });
          if (
            this.state.parentFolderPath !== "/" &&
            this.state.parentFolderPath !== ""
          ) {
            this.selectFolder(
              this.state.parentFolderPath,
              this.state.sortDir,
              this.state.sortField,
              output.parentFolderId,
            );
          } else {
            this.selectClient(
              this.state.clientId,
              this.state.sortDir,
              this.state.sortField,
            );
          }
        } else {
          this.setState({
            folderError: output.message,
            saveMessage: "Create Folder Failed!",
            saveMessageType: 0,
          });
        }
      });
  };

  onDeleteFolder = (clientId, folderId) => {
    const token = store.getState().auth.token;
    const formData = new FormData();
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    formData.append("clientId", clientId);
    formData.append("folderId", folderId);

    fetch(`${API_URL}/deleteFolder`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          this.setState({
            folderName: "",
            addFolder: false,
            editFolder: false,
          });
          if (
            this.state.parentFolderPath !== "/" &&
            this.state.parentFolderPath !== ""
          ) {
            this.selectFolder(
              this.state.parentFolderPath,
              this.state.sortDir,
              this.state.sortField,
              this.state.folderId,
            );
          } else {
            this.selectClient(
              clientId,
              this.state.sortDir,
              this.state.sortField,
            );
          }
        } else {
          this.setState({
            saveMessage: "Delete Folder Failed!",
            saveMessageType: 0,
          });
        }
      });
  };

  cancelUpload = () => {
    this.setState({
      uploadedFiles: [],
      uploadWarning: "",
      pendingFiles: [],
      fileList: [],
      errorFiles: [],
      editFile: false,
      addFolder: false,
      editFolder: false,
      uploadFiles: false,
      totalDocuments: 0,
    });
  };

  clickUpload = () => {
    this.setState({
      uploadFiles: true,
      uploadedFiles: [],
      uploadWarning: "",
      pendingFiles: [],
      fileList: [],
      errorFiles: [],
    });
  };

  uploadFiles = (uploadingFiles) => {
    const uploadedFiles = Array.from(uploadingFiles);
    const formData = new FormData();
    const token = store.getState().auth.token;
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    let uploadError = 0;
    formData.append("clientId", this.state.clientId);
    formData.append("folderId", this.state.folderId);
    formData.append("fullPath", this.state.parentFolderPath);
    if (uploadError !== 1) {
      uploadedFiles.forEach((file, i) => {
        if (this.state.fileList.includes(file.name)) {
          this.setState({
            uploadWarning: this.state.uploadWarning + " " + file.name,
          });
        } else {
          this.setState({
            pendingFiles: [...this.state.pendingFiles, file.name],
          });
          formData.append(i, file);
        }
      });

      // SET UPLOADING TO TRUE
      this.setState({ uploading: true });
      fetch(`${API_URL}/fileUpload`, {
        method: "POST",
        headers: { Authorization: token },
        body: formData,
      })
        .then((res) => res.json())
        .then((filesUploaded) => {
          if (typeof filesUploaded.documents !== "undefined") {
            filesUploaded.documents.map((file, index) => {
              if (file.status === 1) {
                let tmpFileName = file.fileName;
                tmpFileName = tmpFileName.replace("-", " ");
                tmpFileName = tmpFileName.replace("_", " ");
                this.setState({
                  uploadedFiles: [...this.state.uploadedFiles, file],
                  ["fileName" + index + "id"]: file.id,
                  ["fileName" + index]: tmpFileName,
                  fileList: [...this.state.fileList, file.fileName],
                  pendingFiles: this.state.pendingFiles.filter(
                    (filename) => filename !== file.fileName,
                  ),
                });
              } else {
                this.setState({
                  errorFiles: [
                    ...this.state.errorFiles,
                    { error: file.statusReason, name: file.fileName },
                  ],
                  fileList: [...this.state.fileList, file.fileName],
                  pendingFiles: this.state.pendingFiles.filter(
                    (filename) => filename !== file.fileName,
                  ),
                });
              }
              return true;
            });
          }
          this.setState({ totalDocuments: this.state.uploadedFiles.length });
        });
    }
  };

  saveUploadFileNames = () => {
    const { uploadedFiles } = this.state;
    const token = store.getState().auth.token;
    const formData = new FormData();
    this.setState({ uploadWarning: "", saveMessage: "", saveMessageType: "" });
    formData.append("clientId", this.state.clientId);
    uploadedFiles.forEach((file, index) => {
      formData.append("file" + index + "Name", this.state["fileName" + index]);
      formData.append(
        "file" + index + "ID",
        this.state["fileName" + index + "id"],
      );
    });
    formData.append("filesLength", uploadedFiles.length);

    fetch(`${API_URL}/fileUpdate`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1) {
          this.setState({
            uploadFiles: false,
            uploadedFiles: [],
            totalDocuments: 0,
          });
          if (
            this.state.parentFolderPath !== "/" &&
            this.state.parentFolderPath !== ""
          ) {
            this.selectFolder(
              this.state.parentFolderPath,
              this.state.sortDir,
              this.state.sortField,
              this.state.folderId,
            );
          } else {
            this.selectClient(this.state.clientId);
          }
        } else {
          this.setState({ saveMessage: "Update Failed!", saveMessageType: 0 });
        }
      });
  };

  searchFunction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const token = store.getState().auth.token;
    formData.append("searchTerm", this.state.tmpSearchTerm);
    formData.append("clientId", this.state.clientId);
    if (this.state.tmpSearchTerm !== "") {
      // SET UPLOADING TO TRUE
      this.setState({ uploading: true });
      fetch(`${API_URL}/searchResults`, {
        method: "POST",
        headers: { Authorization: token },
        body: formData,
      })
        .then((res) => res.json())
        .then((results) => {
          this.setState({
            showSearchResults: true,
            searchResults: results.searchResults,
          });
        });
    }
  };

  clearSearchResults = () => {
    this.setState({ showSearchResults: false, searchResults: {} });
  };

  updateSort = (tmpSortField) => {
    let sortDir = this.state.sortDir;
    let sortField = this.state.sortField;

    if (tmpSortField !== sortField) {
      sortDir = "DESC";
      sortField = tmpSortField;
    } else if (sortDir === "") sortDir = "DESC";
    else if (sortDir === "ASC") sortDir = "DESC";
    else sortDir = "ASC";

    this.setState({ sortDir: sortDir, sortField: sortField });

    if (
      this.state.parentFolderPath !== "/" &&
      this.state.parentFolderPath !== ""
    ) {
      this.selectFolder(
        this.state.parentFolderPath,
        sortDir,
        sortField,
        this.state.folderId,
      );
    } else {
      this.selectClient(this.state.clientId, sortDir, sortField);
    }
  };

  render() {
    let userRole;
    let userInfo;
    if (this.props?.auth?.user) {
      const { user } = this.props.auth;
      userRole = user.userRole;
      userInfo = user.user;
    } else {
      return <Loading />;
    }

    const {
      showSearchResults,
      searchResults,
      isLoaded,
      showType,
      folderError,
      clientId,
      clients,
      tmpSearchTerm,
      sortField,
      sortDir,
      folderTypes,
      folderType,
      totalDocuments,
      uploadWarning,
      pendingFiles,
      errorFiles,
      uploadedFiles,
      saveMessageType,
      saveMessage,
      successMessage,
      folders,
      files,
      uploadFiles,
      clientName,
      parentFolderPath,
      addFolder,
      editFolder,
      folderName,
      editFile,
      fileName,
      fileUrl,
      breadcrumb,
    } = this.state;

    if (uploadFiles === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">Documents</h1>
              {successMessage && (
                <div className="col-12">
                  <div className="alert alert-success">{successMessage}</div>
                </div>
              )}
            </div>
            <div className="container">
              <div className="row boxWhite">
                {clientId !== 0 && (
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb col-12">
                      <li className="breadcrumb-item btn-link cursorClick">
                        <a href="/documents">Businesses</a>
                      </li>
                      {clientId !== 0 &&
                        clientId !== null &&
                        typeof clientId !== "undefined" &&
                        parentFolderPath === "/" && (
                          <li className="breadcrumb-item">{clientName}</li>
                        )}
                      {clientId !== 0 &&
                        clientId !== null &&
                        typeof clientId !== "undefined" &&
                        parentFolderPath !== "/" && (
                          <li className="breadcrumb-item btn-link cursorClick">
                            <a href={`/documents/${clientId}`}>{clientName}</a>
                          </li>
                        )}
                      {parentFolderPath !== "" && (
                        <DocumentCenterBreadCrumb
                          breadcrumb={breadcrumb}
                          clientId={clientId}
                          parentFolderPath={parentFolderPath}
                          sortDir={this.state.sortDir}
                          sortField={this.state.sortField}
                        />
                      )}
                    </ol>
                  </nav>
                )}
                <div className="col-12">
                  <h3>Upload a Document</h3>
                  <FormInputFileDragAndDrop
                    handleDrop={this.uploadFiles}
                    totalDocuments={totalDocuments}
                  >
                    {totalDocuments !== 0 && (
                      <div className="col-12 text-right">
                        Total Files: {totalDocuments}
                      </div>
                    )}
                    {saveMessageType === 0 && (
                      <div className="text-center alert alert-danger py-2 mb-2 rounded">
                        {saveMessage}
                      </div>
                    )}
                    {saveMessageType === 1 && (
                      <div className="text-center alert alert-success py-2 mb-2 rounded">
                        {saveMessage}
                      </div>
                    )}
                    <div className="DragDropFiles">
                      {uploadWarning && (
                        <div className="alert alert-danger py-2 mb-2 rounded">
                          A file with the same name has already been uploaded,
                          if the file you are uploading is a different file then
                          rename it and try again. : {uploadWarning}
                        </div>
                      )}
                      {uploadedFiles && (
                        <>
                          {uploadedFiles.map((file, index) => {
                            return (
                              <div key={index} className="container">
                                <div className="form-group row border-bottom pb-2 bg-dark text-light">
                                  <label
                                    htmlFor="fileName0"
                                    className="col-sm-12 m-0 pt-2 pb-2"
                                  >
                                    File Name: {file.fileName}
                                  </label>
                                </div>
                                <input
                                  type="hidden"
                                  name={`fileName${index}id`}
                                  value={this.state["fileName" + index + "id"]}
                                />
                                <FormInput
                                  type="text"
                                  name={`fileName${index}`}
                                  value={this.state["fileName" + index]}
                                  label="File Name"
                                  placeholder="name ..."
                                  helpText="Write short title of what the file is, example: '2018 personal tax return'"
                                  onChange={this.onChange}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                      {pendingFiles && (
                        <>
                          {pendingFiles.map((file, index) => (
                            <div key={index} className="container my-1">
                              <div className="form-group row bg-dark text-light">
                                <label
                                  htmlFor="fileName0"
                                  className="col-sm-12 m-0 pt-2 pb-2 small"
                                >
                                  File Name: {file}{" "}
                                  <img src={gearsImage} alt="loading" />
                                </label>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {errorFiles && (
                        <>
                          {errorFiles.map((file, index) => (
                            <div key={index} className="container">
                              <div className="form-group row border-bottom pb-2 bg-danger text-light">
                                <label
                                  htmlFor="fileName0"
                                  className="col-sm-12 m-0 pt-2 pb-2"
                                >
                                  File Name: {file.name} - Status: {file.error}
                                </label>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </FormInputFileDragAndDrop>
                  {totalDocuments > 0 && (
                    <button
                      className="btn btn-success"
                      onClick={() => this.saveUploadFileNames()}
                    >
                      Save & Close
                    </button>
                  )}
                  {totalDocuments === 0 && (
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.cancelUpload()}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (addFolder === true || editFolder === true) {
      return (
        <div className="container py-4 my-4">
          <div className="row">
            <div className="col-12">
              <h1>Add / Edit Folder</h1>
            </div>
          </div>
          {folderError !== "" && (
            <div className="row">
              <div className="col-12">
                <div className="alert alert-danger">{folderError}</div>
              </div>
            </div>
          )}

          <div className="row boxWhite">
            {clientId !== 0 && (
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item btn-link cursorClick">
                    <a href="/documents">Businesses</a>
                  </li>
                  {clientId !== 0 &&
                    clientId !== null &&
                    typeof clientId !== "undefined" &&
                    parentFolderPath === "/" && (
                      <li className="breadcrumb-item">{clientName}</li>
                    )}
                  {clientId !== 0 &&
                    clientId !== null &&
                    typeof clientId !== "undefined" &&
                    parentFolderPath !== "/" && (
                      <li className="breadcrumb-item btn-link cursorClick">
                        <a href={`/documents/${clientId}`}>{clientName}</a>
                      </li>
                    )}

                  {parentFolderPath !== "" && (
                    <DocumentCenterBreadCrumb
                      breadcrumb={breadcrumb}
                      clientId={clientId}
                      parentFolderPath={parentFolderPath}
                      sortDir={this.state.sortDir}
                      sortField={this.state.sortField}
                    />
                  )}
                </ol>
              </nav>
            )}
            {showType === 1 && (
              <div className="col-12">
                <FormInput
                  type="dropdown"
                  className="form-control"
                  name="folderType"
                  label="Folder Type"
                  onChange={this.onChange}
                  placeholder=""
                  dropdown={folderTypes}
                  value={folderType}
                />
              </div>
            )}

            <div className="col-12">
              <FormInput
                type="text"
                name="folderName"
                value={folderName}
                label="Folder Name"
                placeholder="folder name ..."
                helpText=""
                onChange={this.onChange}
              />
            </div>

            <div className="col-12">
              <button
                className="btn btn-success"
                onClick={() => this.saveFolderName()}
              >
                Save & Close
              </button>{" "}
              {editFolder === true && (
                <button
                  className="btn btn-secondary"
                  onClick={() => this.cancelEdit()}
                >
                  Cancel
                </button>
              )}
              {addFolder === true && (
                <button
                  className="btn btn-secondary"
                  onClick={() => this.cancelAddFolder()}
                >
                  Cancel
                </button>
              )}
            </div>

            <div className="col-12 mt-4">
              <h2>Notes</h2>
              <p>
                <b>Public Folder</b> - This folder type will be unique to each
                business. All business will have their own version of this
                folder. Files uploaded to it will only be visible by that
                business and the users assigned to it.
              </p>
              <p>
                <b>Private Folder</b> - This folder type will be unique to each
                business. All business will have their own version of this
                folder. Files uploaded to it will only be visible by{" "}
                <u>
                  <b>your business</b>
                </u>{" "}
                and{" "}
                <u>
                  <b>your users</b>
                </u>{" "}
                that are assigned to it and not the business owner.
              </p>
            </div>
          </div>
        </div>
      );
    } else if (editFile === true) {
      return (
        <div className="container py-4 my-4">
          <div className="row">
            <div className="col-12">
              <h1>Edit File Description</h1>
            </div>
          </div>
          <div className="row boxWhite">
            {clientId !== 0 && (
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item btn-link cursorClick">
                    <a href="/documents">Businesses</a>
                  </li>
                  {clientId !== 0 &&
                    clientId !== null &&
                    typeof clientId !== "undefined" &&
                    parentFolderPath === "/" && (
                      <li className="breadcrumb-item">{clientName}</li>
                    )}
                  {clientId !== 0 &&
                    clientId !== null &&
                    typeof clientId !== "undefined" &&
                    parentFolderPath !== "/" && (
                      <li className="breadcrumb-item btn-link cursorClick">
                        <a href={`/documents/${clientId}`}>{clientName}</a>
                      </li>
                    )}

                  {parentFolderPath !== "" && (
                    <DocumentCenterBreadCrumb
                      breadcrumb={breadcrumb}
                      clientId={clientId}
                      parentFolderPath={parentFolderPath}
                      sortDir={this.state.sortDir}
                      sortField={this.state.sortField}
                    />
                  )}
                </ol>
              </nav>
            )}

            <div className="col-12">
              <div className="container">
                <div className="form-group row border-bottom pb-2 bg-dark text-light">
                  <label
                    htmlFor="fileName0"
                    className="col-sm-12 m-0 pt-2 pb-2"
                  >
                    File: {fileUrl}
                  </label>
                </div>
                <FormInputText
                  name="fileName"
                  value={fileName}
                  label="File Name"
                  placeholder="name ..."
                  helpText="Write short title of what the file is, example: 2018 personal tax return"
                  onChange={this.onChange}
                />
              </div>
              <button
                className="btn btn-success"
                onClick={() => this.saveFileName()}
              >
                Save & Close
              </button>{" "}
              <button
                className="btn btn-secondary"
                onClick={() => this.cancelEdit()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    } else if (isLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">Documents</h1>
              {successMessage && (
                <div className="col-12">
                  <div className="alert alert-success">{successMessage}</div>
                </div>
              )}
            </div>
            <div className="container">
              <div className="row boxWhite">
                {clientId === 0 && showSearchResults === false && (
                  <ClientList clients={clients} />
                )}

                {clientId !== 0 && showSearchResults === false && (
                  <DocumentList
                    clientId={clientId}
                    clientName={clientName}
                    breadcrumb={breadcrumb}
                    folders={folders}
                    files={files}
                    parentFolderPath={parentFolderPath}
                    folderType={folderType}
                    userInfo={userInfo}
                    userRole={userRole}
                    sortDir={sortDir}
                    sortField={sortField}
                    tmpSearchTerm={tmpSearchTerm}
                    onChange={this.onChange}
                    updateSort={this.updateSort}
                    searchFunction={this.searchFunction}
                    clickUpload={this.clickUpload}
                    addFolder={this.handleShowAddFolder}
                    editFolder={editFolder}
                    deleteFolder={this.deleteFolder}
                    editFile={this.editFile}
                    deleteFile={this.deleteFile}
                    moveFile={this.moveFile}
                    downloadFile={this.downloadFile}
                  />
                )}
                {showSearchResults === true && (
                  <div className="col-12">
                    <h3>Search Results</h3>
                    <p>Select the client to view.</p>

                    <div className="d-flex bd-highlight mb-3">
                      <div className="mr-auto p-2 bd-highlight">
                        <button
                          className="btn btn-primary btn-sm mb-2"
                          onClick={() => this.clearSearchResults()}
                        >
                          <i className="fas fa-file-medical" /> Clear Search
                          Results
                        </button>
                      </div>
                      <div className="p-2 bd-highlight">
                        <form
                          className="form-inline my-2 my-sm-0"
                          method="post"
                          action="/documents/search"
                          id="searchscript"
                          name="searchscript"
                          onSubmit={this.searchFunction}
                        >
                          <input
                            onChange={this.onChange}
                            className="form-control form-control-sm mr-sm-2"
                            id="tmpSearchTerm"
                            name="tmpSearchTerm"
                            type="search"
                            placeholder="Search"
                            value={tmpSearchTerm}
                            aria-label="Search"
                          />
                          <a
                            className="btn btn-primary btn-sm my-2 my-sm-0"
                            onClick={this.searchFunction}
                            href="/documents/search"
                          >
                            <i className="fas fa-search" />
                          </a>
                        </form>
                      </div>
                    </div>
                    <DocumentCenterSearchResults
                      searchResults={searchResults}
                      sortField={sortField}
                      sortDir={sortDir}
                      updateSearchFilter={this.updateSearchFilter}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  documentList: state.pages.documentList,
  dataList: state.pages.dataList,
  clientList: state.pages.clientList,
});
export default compose(
  withRouter,
  connect(mapStateToProps, {
    getDocuments,
    deleteFile,
    moveFile,
    archiveFile,
    getDocumentClients,
    getClientFolders,
    getClientFiles,
    deleteFolder,
  }),
)(Document);
