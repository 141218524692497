import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import {
  deleteDefaultFolder,
  getDefaultFolders,
  syncFolders,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import { confirmAlert } from "react-confirm-alert";

class Config_Default_Folders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: "",
      error: {},
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    deleteDefaultFolder: PropTypes.func.isRequired,
    getDefaultFolders: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getDefaultFolders();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (
        typeof this.props.data !== "undefined" &&
        prevProps.data !== this.props.data
      ) {
        this.setState({
          data: this.props.data,
        });
      }
    }
  }

  onDelete = (deleteId) => {
    this.props.deleteDefaultFolder(deleteId);
  };

  confirmSyncFolders = () => {
    confirmAlert({
      title: "Confirm Sync Folders",
      message:
        "Are you sure you want to sync these folders. This action can not be undone without manually deleting the folders from each business.",
      buttons: [
        {
          label: "Yes, Sync",
          onClick: () => this.syncFolders(),
        },
        {
          label: "No, Do Not Sync",
        },
      ],
    });
  };

  syncFolders = () => {
    this.props.syncFolders();
  };

  render() {
    const { successMessage, data } = this.state;

    if (typeof data !== "undefined") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">Default Folders</h1>
            </div>

            <div className="col-12 mb-2">
              <Link to="/config" className="btn btn-sm btn-secondary">
                Back
              </Link>
            </div>

            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}

            <div className="container">
              <div className="row boxWhite">
                <Link
                  to="/config/default-folders/0"
                  className="btn btn-primary btn-sm m-1"
                >
                  Add Folder
                </Link>
                <button
                  onClick={() => {
                    this.confirmSyncFolders();
                  }}
                  className="btn btn-warning btn-sm m-1"
                >
                  Sync Folders with All Businesses
                </button>
                <table className="table table-sm table-striped table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Folder Name</th>
                      <th scope="col">Parent Path</th>
                      <th scope="col">Full Path</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof data.defaultFolders !== "undefined" && (
                      <Fragment>
                        {data.defaultFolders.map((folder, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">
                                {folder.folderType === "1" && (
                                  <i className="fas fa-globe-americas" />
                                )}
                                {folder.folderType === "2" && (
                                  <i className="fas fa-user-friends" />
                                )}
                                {folder.folderType === "3" && (
                                  <i className="fas fa-user-lock" />
                                )}
                              </td>
                              <td className="align-middle">
                                {folder.folderName}
                              </td>
                              <td className="align-middle">
                                {folder.parentFolderPath}
                              </td>
                              <td className="align-middle">
                                {folder.fullPath}
                              </td>
                              <td
                                className="align-middle"
                                style={{ minWidth: "100px" }}
                              >
                                <Link
                                  to={"/config/default-folders/" + folder.id}
                                  className="btn btn-primary mr-1 btn-sm"
                                >
                                  <i className="fas fa-edit" />
                                </Link>
                                <Link
                                  to="/config/default-folders/"
                                  onClick={() => {
                                    window.confirm(
                                      "Are you sure you wish to delete this folder?",
                                    ) && this.onDelete(folder.id);
                                  }}
                                  className="btn btn-danger mr-1 btn-sm"
                                >
                                  <i className="fas fa-trash-alt" />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  deleteDefaultFolder,
  getDefaultFolders,
  syncFolders,
})(Config_Default_Folders);
