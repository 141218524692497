import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { getInvite, saveUserInvite } from "../../../client/actions/apiActions";
import ItemText from "../../component/itemText";
import PasswordStrengthMeter from "../../component/PasswordStrengthMeter";
import PropTypes from "prop-types";
import Loading from "../../layout/Loading";
import { compose } from "redux";
import { withRouter } from "../../../utils";
class ClientsInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      firstName: "",
      lastName: "",
      isPerson: false,
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      errors: {},
      successMessage: "",
      errorMessage: "",
      redirectUser: false,
    };
  }
  componentDidMount() {
    let { id } = this.props.router.params;
    this.props.getInvite(id);
  }
  static propTypes = {
    profileData: PropTypes.object.isRequired,
  };
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { isLoaded } = this.state;
    if (isLoaded === false && typeof nextProps.profileData !== "undefined") {
      this.setState({
        isLoaded: true,
        isPerson: nextProps.profileData.person,
        firstName: nextProps.profileData.firstName,
        lastName: nextProps.profileData.lastName,
        phone: nextProps.profileData.phone,
        email: nextProps.profileData.email,
      });
    }
    if (nextProps.profileData.status === 0) {
      this.setState({ errorMessage: nextProps.profileData.message });
    }
    if (nextProps.profileData.status === 1) {
      this.setState({ redirectUser: true });
      this.props.router.navigate("/login");
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveUserInvite = () => {
    let { id } = this.props.router.params;
    const { firstName, lastName, email, phone, password, confirmPassword } =
      this.state;
    this.props.saveUserInvite(
      id,
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
    );
  };
  render() {
    const {
      isPerson,
      isLoaded,
      redirectUser,
      firstName,
      lastName,
      email,
      phone,
      password,
      confirmPassword,
      successMessage,
      errorMessage,
      errors,
    } = this.state;
    if (redirectUser === true) return <Navigate to="/login" />;
    if (isLoaded === true) {
      if (isPerson === true || isPerson === "true") {
        return (
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <div className="card card-body mt-5 ">
                  <div className="col-12 text-center">
                    <img
                      src="/assets/images/logo.png"
                      alt="CE-TECH"
                      width="180"
                      className="img-fluid"
                    />
                  </div>
                  <h1 className="text-center my-4">Account Registration</h1>
                  <p>
                    To finish creating your account please enter the information
                    below.
                  </p>
                  <hr noshade="true" />
                  {successMessage && (
                    <div className="col-12">
                      <div className="alert alert-success">
                        {successMessage}
                      </div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className="col-12">
                      <div className="alert alert-danger">{errorMessage}</div>
                    </div>
                  )}

                  <ItemText
                    errors={errors.firstName}
                    label="First Name"
                    type="text"
                    id="firstName"
                    value={firstName}
                    placeholder="enter first name"
                    onChange={this.onChange}
                  />
                  <ItemText
                    errors={errors.lastName}
                    label="Last Name"
                    type="text"
                    id="lastName"
                    value={lastName}
                    placeholder="enter last name"
                    onChange={this.onChange}
                  />
                  <ItemText
                    errors={errors.email}
                    label="Email Address"
                    type="text"
                    id="email"
                    value={email}
                    placeholder="enter email address"
                    onChange={this.onChange}
                  />

                  <ItemText
                    errors={errors.phone}
                    label="Phone"
                    type="text"
                    id="phone"
                    value={phone}
                    placeholder="enter phone number"
                    onChange={this.onChange}
                  />

                  <h3>Password</h3>
                  <div className="form-group row">
                    <label
                      className="col-sm-4 col-form-label"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        onChange={this.onChange}
                        placeholder=""
                        value={password}
                      />
                      <br />
                      <PasswordStrengthMeter password={password} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-4 col-form-label"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={this.onChange}
                        placeholder=""
                        value={confirmPassword}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-success my-2"
                    onClick={() => this.saveUserInvite()}
                  >
                    <i className="fas fa-save" /> SAVE
                  </button>
                  <Link className="btn btn-secondary my-2" to="/login">
                    <i className="fas fa-ban" /> CANCEL
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <div className="card card-body mt-5 ">
                  <div className="col-12 text-center">
                    <img
                      src="/assets/images/logo.png"
                      alt="CE-TECH"
                      width="180"
                      className="img-fluid"
                    />
                  </div>
                  <h1 className="text-center my-4">Account Registration</h1>
                  <hr noshade="true" />
                  <div className="col-12">
                    <div className="alert alert-danger">
                      Invalid invite code
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => ({
  profileData: state.pages.profileData,
});
export default compose(
  withRouter,
  connect(mapStateToProps, { getInvite, saveUserInvite }),
)(ClientsInvite);
