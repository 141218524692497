import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import Folder from "../Folder";
import { connect } from "react-redux";
import {
  getSortFolders,
  saveSortFolders,
} from "../../../../client/actions/apiActions";
import Loading from "../../../layout/Loading";

const FolderList = (props) => {
  const { isDragging, sortFolderId } = props;
  const [loading, setLoading] = useState(true);

  const [folders, setFolders] = useState([]);
  const [originalFolders, setOriginalFolders] = useState([]);

  const moveFolder = useCallback((dragIndex, hoverIndex) => {
    setFolders((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const renderFolder = useCallback((folder, index) => {
    return (
      <Folder
        key={folder.id}
        index={index}
        folder={folder}
        text={folder.folderName}
        moveFolder={moveFolder}
      />
    );
  }, []);

  const saveSortFolders = () => {
    props.saveSortFolders(sortFolderId, folders);
    setLoading(true);
  };

  useEffect(() => {
    props.getSortFolders(sortFolderId);
  }, []);

  useEffect(() => {
    props.getSortFolders(sortFolderId);
  }, [sortFolderId]);

  useEffect(() => {
    if (props.data.pageName === "getSortFolders") {
      if (props.data?.sortedFolders === null) {
        setFolders(props.data?.originalFolders);
      } else {
        setFolders(props.data.sortedFolders);
      }
      setLoading(false);
    }

    if (props.data.pageName === "saveSortFolders") {
      props.setEditSortFolders(false);
      props.getSortList(false);
      setLoading(false);
    }
  }, [props.data]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <button onClick={saveSortFolders} className="btn btn-primary btn-sm m-1">
        Save Sort
      </button>

      <button
        onClick={() => {
          setFolders([]);
          props.setEditSortFolders(false);
        }}
        className="btn btn-secondary btn-sm m-1"
      >
        Cancel
      </button>
      <table className="table table-sm table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">Type</th>
            <th scope="col">Folder Name</th>
            <th scope="col">Full Path</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <DndProvider backend={HTML5Backend}>
            <>{folders?.map((folder, index) => renderFolder(folder, index))}</>
          </DndProvider>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  getSortFolders,
  saveSortFolders,
})(FolderList);
