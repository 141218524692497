import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveUserRole, getUserRole } from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import FormInput from "../../form/FormInput";
import store from "../../../client/store";
import { API_URL } from "../../../config";
import { compose } from "redux";
import { withRouter } from "../../../utils";

let listOptions = [
  { name: "All", value: "All" },
  { name: "Selectable", value: "Selectable" },
  { name: "None", value: "None" },
];

let roleListOptions = [
  { name: "Portal User", value: 1 },
  { name: "Customer User", value: 2 },
];

class Config_Email_Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      parentUrl: "/config/user-roles",

      roleName: "",
      roleType: "2",
      viewCustomers: "None",
      viewFolders: "None",
      viewFiles: "None",

      companyCanAdd: false,
      companyCanEdit: false,
      companyCanDelete: false,

      usersCanInvite: false,
      usersCanEdit: false,
      usersCanDelete: false,

      foldersAdd: false,
      foldersDelete: false,

      filesCanEdit: false,
      filesCanUpload: false,
      filesCanDownload: false,
      filesCanDelete: false,
      filesCanMove: false,

      successMessage: "",
      error: {},
      pageType: "",
      userId: 0,
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveUserRole: PropTypes.func.isRequired,
    getUserRole: PropTypes.func.isRequired,
  };
  componentDidMount() {
    let { id } = this.props.router.params;

    if (typeof id !== "undefined" && id !== "0") {
      this.props.getUserRole(id);
      this.setState({ pageType: "Edit", userId: id });
    } else {
      this.setState({ pageType: "Add", isLoaded: true, userId: id });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      let data = this.props.data.userRole;

      let companyCanAdd = parseInt(data.companyCanAdd) !== 0;
      let companyCanEdit = parseInt(data.companyCanEdit) !== 0;
      let companyCanDelete = parseInt(data.companyCanDelete) !== 0;

      let usersCanInvite = parseInt(data.usersCanInvite) !== 0;
      let usersCanEdit = parseInt(data.usersCanEdit) !== 0;
      let usersCanDelete = parseInt(data.usersCanDelete) !== 0;

      let foldersAdd = parseInt(data.foldersAdd) !== 0;
      let foldersDelete = parseInt(data.foldersDelete) !== 0;

      let filesCanEdit = parseInt(data.filesCanEdit) !== 0;
      let filesCanUpload = parseInt(data.filesCanUpload) !== 0;
      let filesCanDownload = parseInt(data.filesCanDownload) !== 0;
      let filesCanDelete = parseInt(data.filesCanDelete) !== 0;
      let filesCanMove = parseInt(data.filesCanMove) !== 0;

      this.setState({
        isLoaded: true,
        roleName: data.roleName,
        viewCustomers: data.viewCustomers,
        viewFolders: data.viewFolders,
        viewFiles: data.viewFiles,

        companyCanAdd: companyCanAdd,
        companyCanEdit: companyCanEdit,
        companyCanDelete: companyCanDelete,

        usersCanInvite: usersCanInvite,
        usersCanEdit: usersCanEdit,
        usersCanDelete: usersCanDelete,

        foldersAdd: foldersAdd,
        foldersDelete: foldersDelete,

        filesCanEdit: filesCanEdit,
        filesCanUpload: filesCanUpload,
        filesCanDownload: filesCanDownload,
        filesCanDelete: filesCanDelete,
        filesCanMove: filesCanMove,

        roleType: data.roleType,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onCancel = () => {
    this.props.router.navigate(this.state.parentUrl);
  };
  onSave = () => {
    let id;
    if (this.state.userId !== "") {
      id = this.state.userId;
    } else {
      id = "";
    }
    const {
      roleName,
      viewCustomers,
      viewFolders,
      viewFiles,
      usersCanInvite,
      companyCanAdd,
      companyCanEdit,
      companyCanDelete,
      filesCanEdit,
      filesCanMove,
      filesCanDelete,
      usersCanEdit,
      usersCanDelete,
      foldersAdd,
      foldersDelete,
      roleType,
      filesCanUpload,
      filesCanDownload,
    } = this.state;
    const token = store.getState().auth.token;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("roleName", roleName);
    formData.append("roleType", roleType);

    formData.append("viewCustomers", viewCustomers);
    formData.append("viewFolders", viewFolders);
    formData.append("viewFiles", viewFiles);

    formData.append("companyCanAdd", companyCanAdd);
    formData.append("companyCanEdit", companyCanEdit);
    formData.append("companyCanDelete", companyCanDelete);

    formData.append("usersCanInvite", usersCanInvite);
    formData.append("usersCanEdit", usersCanEdit);
    formData.append("usersCanDelete", usersCanDelete);

    formData.append("foldersAdd", foldersAdd);
    formData.append("foldersDelete", foldersDelete);

    formData.append("filesCanEdit", filesCanEdit);
    formData.append("filesCanUpload", filesCanUpload);
    formData.append("filesCanDownload", filesCanDownload);
    formData.append("filesCanDelete", filesCanDelete);
    formData.append("filesCanMove", filesCanMove);

    fetch(`${API_URL}/saveUserRole`, {
      method: "POST",
      headers: { Authorization: token },
      body: formData,
    })
      .then((res) => res.json())
      .then((output) => {
        if (output.status === 1 || output.success === 1) {
          this.setState({ isLoaded: false });
          this.props.router.navigate(this.state.parentUrl);
        } else {
          this.setState({ errorMessage: output.message });
        }
      });
  };
  hangleToggleChange = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  render() {
    const {
      isLoaded,
      successMessage,
      errorMessage,
      error,
      pageType,
      companyCanAdd,
      companyCanEdit,
      companyCanDelete,
      usersCanInvite,
      filesCanEdit,
      filesCanMove,
      filesCanDelete,
      usersCanEdit,
      usersCanDelete,
      foldersAdd,
      foldersDelete,
      roleType,
      filesCanUpload,
      filesCanDownload,
      roleName,
      viewCustomers,
      viewFolders,
      viewFiles,
    } = this.state;

    if (isLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">{pageType} User Role</h1>
            </div>
            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}
            {errorMessage && (
              <div className="col-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            )}
            <div className="container">
              <div className="row boxWhite">
                <div className="col-12">
                  <FormInput
                    type="input"
                    className="form-control"
                    name="roleName"
                    label="Role Name"
                    onChange={this.onChange}
                    placeholder=""
                    value={roleName}
                    error={error.roleName}
                  />

                  <FormInput
                    type="radio"
                    className="form-control"
                    name="roleType"
                    label="Role Type"
                    onChange={this.onChange}
                    placeholder=""
                    value={roleType}
                    valueOptions={roleListOptions}
                    defaultValue={2}
                    error={error.roleType}
                  />

                  <h3>View Permissions</h3>
                  <FormInput
                    type="radio"
                    className="form-control"
                    name="viewCustomers"
                    label="View Customers"
                    onChange={this.onChange}
                    placeholder=""
                    value={viewCustomers}
                    valueOptions={listOptions}
                    defaultValue="None"
                    error={error.viewCustomers}
                  />

                  <FormInput
                    type="radio"
                    className="form-control"
                    name="viewFolders"
                    label="View Folders"
                    onChange={this.onChange}
                    placeholder=""
                    value={viewFolders}
                    valueOptions={listOptions}
                    defaultValue="None"
                    error={error.viewFolders}
                  />

                  <FormInput
                    type="radio"
                    className="form-control"
                    name="viewFiles"
                    label="View Files"
                    onChange={this.onChange}
                    placeholder=""
                    value={viewFiles}
                    valueOptions={listOptions}
                    defaultValue="None"
                    error={error.viewFiles}
                  />

                  <h3>Company Permissions</h3>
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="companyCanAdd"
                    label="Can Add New Businesses"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={companyCanAdd}
                    defaultValue={false}
                    error={error.companyCanAdd}
                  />
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="companyCanEdit"
                    label="Can Edit Companies"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={companyCanEdit}
                    defaultValue={false}
                    error={error.companyCanEdit}
                  />
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="companyCanDelete"
                    label="Can Delete Companies"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={companyCanDelete}
                    defaultValue={false}
                    error={error.companyCanDelete}
                  />

                  <h3>User Permissions</h3>
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="usersCanInvite"
                    label="Can Invite New Users"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={usersCanInvite}
                    defaultValue={false}
                    error={error.usersCanInvite}
                  />
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="usersCanEdit"
                    label="Can Edit Users"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={usersCanEdit}
                    defaultValue={false}
                    error={error.usersCanEdit}
                  />
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="usersCanDelete"
                    label="Can Delete Users"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={usersCanDelete}
                    defaultValue={false}
                    error={error.usersCanDelete}
                  />

                  <h3>Folder Permissions</h3>
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="foldersAdd"
                    label="Can Add / Edit Folders"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={foldersAdd}
                    defaultValue={false}
                    error={error.foldersAdd}
                  />
                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="foldersDelete"
                    label="Can Delete Folders"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={foldersDelete}
                    defaultValue={false}
                    error={error.foldersDelete}
                  />

                  <h3>File Permissions</h3>
                  <FormInput
                    type="toggle"
                    className="form-control"
                    id="filesCanEdit"
                    name="filesCanEdit"
                    label="Can Edit Files"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={filesCanEdit}
                    defaultValue={false}
                    error={error.filesCanEdit}
                  />

                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="filesCanUpload"
                    label="Can Upload Files"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={filesCanUpload}
                    defaultValue={false}
                    error={error.filesCanUpload}
                  />

                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="filesCanDownload"
                    label="Can Download Files"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={filesCanDownload}
                    defaultValue={false}
                    error={error.filesCanDownload}
                  />

                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="filesCanDelete"
                    label="Can Delete Files"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={filesCanDelete}
                    defaultValue={false}
                    error={error.filesCanDelete}
                  />

                  <FormInput
                    type="toggle"
                    className="form-control"
                    name="filesCanMove"
                    label="Can Move Files"
                    onChangeToggle={this.hangleToggleChange}
                    placeholder=""
                    value={filesCanMove}
                    defaultValue={false}
                    error={error.filesCanMove}
                  />
                </div>

                <div className="col-sm-6  mb-3">
                  <button
                    type="submit"
                    className="btn btn-dark"
                    onClick={() => this.onCancel()}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-sm-6 text-right mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => this.onSave()}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { saveUserRole, getUserRole }),
)(Config_Email_Template);
