import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FancyBoolean from "../../component/FancyBoolean";
import { Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  deleteUserRole,
  getUserRoles,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";

class Config_User_Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: "",
      error: {},
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    deleteUserRole: PropTypes.func.isRequired,
    getUserRoles: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getUserRoles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({
        data: this.props.dataList.userRoles,
      });
    }
  }

  clickDelete = (roleId) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this role?",
      buttons: [
        {
          label: "Yes, Delete",
          onClick: () => this.props.deleteUserRole(roleId),
        },
        {
          label: "No, Do Not Delete",
        },
      ],
    });
  };

  render() {
    const { successMessage, errorMessage, data } = this.state;
    if (typeof data !== "undefined") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">User Roles</h1>
            </div>

            <div className="col-12 my-4">
              <Link to="/config" className="btn btn-sm btn-secondary">
                Back
              </Link>
            </div>

            {successMessage && (
              <div className="col-12">
                <div className="alert alert-success">{successMessage}</div>
              </div>
            )}
            {errorMessage && (
              <div className="col-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            )}

            <div className="container">
              <div className="row boxWhite">
                <div className="col-12">
                  <Link
                    to="/config/user-roles/0"
                    className="btn btn-primary btn-sm mb-3"
                  >
                    Add Role
                  </Link>
                  <h3>Portal User Roles</h3>
                  <table className="table table-sm table-striped table-hover">
                    <thead className="thead-dark small">
                      <tr>
                        <th scope="col">Role Name</th>
                        <th scope="col">Customers</th>
                        <th scope="col">Folders</th>
                        <th scope="col">Files</th>
                        <th scope="col text-center">
                          User
                          <br />
                          Invite
                        </th>
                        <th scope="col text-center">
                          User
                          <br />
                          Edit
                        </th>
                        <th scope="col text-center">
                          User
                          <br />
                          Delete
                        </th>
                        <th scope="col text-center">
                          Folder
                          <br />
                          Add
                        </th>
                        <th scope="col text-center">
                          Folder
                          <br />
                          Delete
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Upload
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Download
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Delete
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Move
                        </th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeof data !== "undefined" && (
                        <Fragment>
                          {data.map((userRole, index) => {
                            if (userRole.roleType === "1") {
                              return (
                                <tr key={index}>
                                  <td className="align-middle">
                                    {userRole.roleName}
                                  </td>
                                  <td className="align-middle text-center">
                                    {userRole.viewCustomers}
                                  </td>
                                  <td className="align-middle text-center">
                                    {userRole.viewFolders}
                                  </td>
                                  <td className="align-middle text-center">
                                    {userRole.viewFiles}
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.usersCanInvite}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.usersCanEdit}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.usersCanDelete}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean value={userRole.foldersAdd} />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.foldersDelete}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanUpload}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanDownload}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanDelete}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanMove}
                                    />
                                  </td>
                                  <td
                                    className="align-middle"
                                    style={{ minWidth: "100px" }}
                                  >
                                    {userRole.editable === "1" && (
                                      <Fragment>
                                        <Link
                                          to={
                                            "/config/user-roles/" + userRole.id
                                          }
                                          className="btn btn-primary mr-1 btn-sm"
                                        >
                                          <i className="fas fa-edit" />
                                        </Link>
                                        <Link
                                          to="/config/user-roles/"
                                          onClick={() => {
                                            this.clickDelete(userRole.id);
                                          }}
                                          className="btn btn-danger mr-1 btn-sm"
                                        >
                                          <i className="fas fa-trash-alt" />
                                        </Link>
                                      </Fragment>
                                    )}
                                  </td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Fragment>
                      )}
                    </tbody>
                  </table>

                  <h3>Customer User Roles</h3>
                  <table className="table table-sm table-striped table-hover">
                    <thead className="thead-dark small">
                      <tr>
                        <th scope="col">Role Name</th>
                        <th scope="col">Customers</th>
                        <th scope="col">Folders</th>
                        <th scope="col">Files</th>
                        <th scope="col text-center">
                          User
                          <br />
                          Invite
                        </th>
                        <th scope="col text-center">
                          User
                          <br />
                          Edit
                        </th>
                        <th scope="col text-center">
                          User
                          <br />
                          Delete
                        </th>
                        <th scope="col text-center">
                          Folder
                          <br />
                          Add
                        </th>
                        <th scope="col text-center">
                          Folder
                          <br />
                          Delete
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Upload
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Download
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Delete
                        </th>
                        <th scope="col text-center">
                          Files
                          <br />
                          Move
                        </th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeof data !== "undefined" && (
                        <Fragment>
                          {data.map((userRole, index) => {
                            if (userRole.roleType === "2") {
                              return (
                                <tr key={index}>
                                  <td className="align-middle">
                                    {userRole.roleName}
                                  </td>
                                  <td className="align-middle text-center">
                                    {userRole.viewCustomers}
                                  </td>
                                  <td className="align-middle text-center">
                                    {userRole.viewFolders}
                                  </td>
                                  <td className="align-middle text-center">
                                    {userRole.viewFiles}
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.usersCanInvite}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.usersCanEdit}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.usersCanDelete}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean value={userRole.foldersAdd} />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.foldersDelete}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanUpload}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanDownload}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanDelete}
                                    />
                                  </td>
                                  <td className="align-middle text-center">
                                    <FancyBoolean
                                      value={userRole.filesCanMove}
                                    />
                                  </td>
                                  <td
                                    className="align-middle"
                                    style={{ minWidth: "100px" }}
                                  >
                                    {userRole.editable === "1" && (
                                      <Fragment>
                                        <Link
                                          to={
                                            "/config/user-roles/" + userRole.id
                                          }
                                          className="btn btn-primary mr-1 btn-sm"
                                        >
                                          <i className="fas fa-edit" />
                                        </Link>
                                        <Link
                                          to="/config/user-roles/"
                                          onClick={() => {
                                            this.clickDelete(userRole.id);
                                          }}
                                          className="btn btn-danger mr-1 btn-sm"
                                        >
                                          <i className="fas fa-trash-alt" />
                                        </Link>
                                      </Fragment>
                                    )}
                                  </td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center my-4">User Roles</h1>
            </div>
            <div className="col-12 my-4">
              <Link to="/config" className="btn btn-sm btn-secondary">
                Back
              </Link>
            </div>
            <div className="col-12 my-4">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  dataList: state.pages.dataList,
});

export default connect(mapStateToProps, { deleteUserRole, getUserRoles })(
  Config_User_Role,
);
