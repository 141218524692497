import React, { Component} from 'react';
import classnames from 'classnames';
import Uploading from "../layout/Uploading";

class FormInputFile extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            uploading: false,
            uploadErrorMessage: ''
        };
        this.onDeleteFile = this.onDeleteFile.bind(this);
    };
    onDeleteFile = (name) => {
        this.props.onValueChange(this.props.name, '');
    };
    render() {
        const { name, value, label, placeholder, helpText, error } = this.props;
        const { uploading, uploadErrorMessage } = this.state;
        return (<div className="form-group row border-bottom pb-2">
            <label className="col-12" htmlFor={name}>{label}</label>
            {uploadErrorMessage !== '' && <div className="alert alert-danger col-12">{uploadErrorMessage}</div>}
            {value && <div className="col-12 py-2"> <b>{value}</b> <button className="btn btn-danger" onClick={() => this.onDeleteFile(name)}>Delete File</button></div> }

            <div className="col-12">
                {uploading === true && <Uploading />}
                {uploading === false && value === '' &&
                    <input
                        id={name}
                        name={name}
                        type="file"
                        className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                        onChange={(e) => this.props.onUpload(e.target.files)}
                        aria-describedby={name+"Help"}
                        placeholder={placeholder}
                    />}
            </div>
            {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div>}
        </div>)
    }
}

export default FormInputFile