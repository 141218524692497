import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import store from "./client/store";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

// PAGES

import Dashboard from "./components/pages/Dashboard";

import DocumentCenter from "./components/pages/documents/DocumentCenter"; // view requests lists
import DocumentCenterAdd from "./components/pages/documents/DocumentCenterAdd"; // create requests

import Clients from "./components/pages/clients/Clients";
import ClientsAdd from "./components/pages/clients/ClientsAdd";
import ClientsInvite from "./components/pages/clients/ClientsInvite";
import ClientsProcessInvite from "./components/pages/clients/ClientsInviteEdit";
import ClientsUserEdit from "./components/pages/clients/Clients_User_Edit";
import ClientsClientEdit from "./components/pages/clients/Clients_Client_Edit";
import ClientsClientAssociations from "./components/pages/clients/Clients_Client_Associations";

import Config from "./components/pages/config/Config";
import ConfigSettings from "./components/pages/config/Config_Settings";
import ConfigUserRoles from "./components/pages/config/Config_User_Roles";
import ConfigUserRolesEdit from "./components/pages/config/Config_User_Roles_Edit";
import ConfigEmailTemplates from "./components/pages/config/Config_Email_Templates";
import ConfigEmailTemplatesEdit from "./components/pages/config/Config_Email_Templates_Edit";
import ConfigDefaultFolders from "./components/pages/config/Config_Default_Folders";
import ConfigDefaultFoldersEdit from "./components/pages/config/Config_Default_Folders_Edit";
import SortFolders from "./components/pages/config/SortFolders";

import Profile from "./components/pages/user/Profile";
import ProfilePassword from "./components/pages/user/ProfilePassword";
import ProfileNotifications from "./components/pages/user/ProfileNotifications";
import ProfileCompany from "./components/pages/user/ProfileCompany";

import Error404 from "./components/pages/Error404";
import Login from "./components/pages/user/Login";
import ForgotPass from "./components/pages/user/ForgotPass";

// CSS
import "./assets/App.css";
import { loadUser } from "./client/actions/auth";

import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import AppTemplate from "./AppTemplate";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppTemplate />}>
      <Route index element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />

      <Route path="documents">
        <Route index element={<DocumentCenter />} />
        <Route path="document/add" element={<DocumentCenterAdd />} />
        <Route path=":clientId" element={<DocumentCenter />} />
        <Route
          path=":clientId/:folderId/:folderName1"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9/:folderName10"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9/:folderName10/:folderName11"
          element={<DocumentCenter />}
        />
        <Route
          path=":clientId/:folderId/:folderName1/:folderName2/:folderName3/:folderName4/:folderName5/:folderName6/:folderName7/:folderName8/:folderName9/:folderName10/:folderName11/:folderName12"
          element={<DocumentCenter />}
        />
      </Route>

      <Route path="clients">
        <Route index element={<Clients />} />
        <Route path="add" element={<ClientsAdd />} />
        <Route path="user_edit/:id" element={<ClientsUserEdit />} />
        <Route path="client_edit/:id" element={<ClientsClientEdit />} />
        <Route
          path="user_association/:id"
          element={<ClientsClientAssociations />}
        />
        <Route path="invite/:companyId" element={<ClientsInvite />} />
      </Route>

      <Route path="profile">
        <Route path="edit" element={<Profile />} />
        <Route path="password" element={<ProfilePassword />} />
        <Route path="notifications" element={<ProfileNotifications />} />
        <Route path="company" element={<ProfileCompany />} />
      </Route>

      <Route path="config">
        <Route index element={<Config />} />
        <Route path="settings" element={<ConfigSettings />} />
        <Route path="user-roles" element={<ConfigUserRoles />} />
        <Route path="user-roles/:id" element={<ConfigUserRolesEdit />} />
        <Route path="email-templates" element={<ConfigEmailTemplates />} />
        <Route
          path="email-templates/:id"
          element={<ConfigEmailTemplatesEdit />}
        />
        <Route path="default-folders" element={<ConfigDefaultFolders />} />
        <Route path="sort-folders" element={<SortFolders />} />
        <Route
          path="default-folders/:id"
          element={<ConfigDefaultFoldersEdit />}
        />
      </Route>

      <Route path="/invite/:id" element={<ClientsProcessInvite />} />
      <Route path="/forgot-password" element={<ForgotPass />} />
      <Route path="/login" element={<Login />} />

      <Route path="*" element={<Error404 />} />
    </Route>,
  ),
  {
    basename: "/",
  },
);

class App extends Component {
  state = {
    androidMessage: null,
  };

  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    );
  }
}
export default App;
