import FormInput from "../../../form/FormInput";
import React, { useState } from "react";

const AddEdit = ({ folder, onSave, saving }) => {
  const [sortingId] = useState(folder.id || "0");
  const [sortingName, setSortingName] = useState(folder.name || "");

  const saveFolder = () => {
    onSave(sortingId, sortingName);
  };

  return (
    <>
      <FormInput
        label="Sorting Name"
        name="sortingName"
        type="text"
        placeholder="Enter Sorting Name"
        value={sortingName}
        onChange={(e) => setSortingName(e.target.value)}
      />

      <button
        onClick={saveFolder}
        className="btn btn-primary btn-sm m-1"
        disabled={saving}
      >
        Save
      </button>
    </>
  );
};
export default AddEdit;
