import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  forgotPass,
  saveForgotPass,
  checkVerificationCode,
} from "../../../client/actions/auth";
import logo from "../../../assets/logo-small.png";
import { withRouter } from "../../../utils";
import { compose } from "redux";

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      successMessage: "",
      errorMessage: "",
      password: "",
      passwordConfirm: "",
      verificationCode: "",
      showVerification: 0,
      showResetScreen: 0,
    };
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (typeof nextProps.userData !== "undefined") {
      if (nextProps.userData.status === 1) {
        this.setState({
          successMessage: nextProps.userData.message,
          showVerification: 1,
          showResetScreen: 0,
        });
      } else if (nextProps.userData.status === 0) {
        this.setState({
          errorMessage: nextProps.userData.message,
          showVerification: 0,
          showResetScreen: 0,
        });
      } else if (nextProps.userData.verificationCodeSuccess === 0) {
        this.setState({
          errorMessage: nextProps.userData.message,
          showVerification: 0,
          showResetScreen: 0,
        });
      } else if (nextProps.userData.verificationCodeSuccess === 1) {
        this.setState({
          successMessage: nextProps.userData.message,
          showVerification: 0,
          showResetScreen: 1,
        });
      } else if (nextProps.userData.successPassword === 1) {
        this.props.router.navigate("/");
      }
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMessage: "",
    });
  onSubmitEmail = (e) => {
    e.preventDefault();
    const { username } = this.state;

    if (username === "") {
      this.setState({
        errorMessage: "You did not enter your email address!",
        successMessage: "",
      });
    } else {
      this.props.forgotPass(username);
    }
  };
  onSubmitVerificationCode = (e) => {
    e.preventDefault();
    const { verificationCode, username } = this.state;

    if (verificationCode === "") {
      this.setState({
        errorMessage: "You did not enter a verification code!",
        successMessage: "",
      });
    } else {
      this.props.checkVerificationCode(verificationCode, username);
    }
  };
  onSubmitPassword = () => {
    const { username, verificationCode, password, passwordConfirm } =
      this.state;

    if (password === "") {
      this.setState({
        errorMessage: "You did not enter a password!",
        successMessage: "",
      });
    } else if (password !== "" && passwordConfirm !== password) {
      this.setState({
        errorMessage: "Your passwords do not match!",
        successMessage: "",
      });
    } else {
      this.props.saveForgotPass(
        username,
        verificationCode,
        password,
        passwordConfirm,
      );
    }
  };
  onCancel = (e) => {
    this.setState({
      username: "",
      successMessage: "",
      errorMessage: "",
      verificationCode: "",
      showVerification: 0,
      showResetScreen: 0,
    });
  };
  onFormSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      username,
      errorMessage,
      successMessage,
      showVerification,
      showResetScreen,
      password,
      passwordConfirm,
      verificationCode,
    } = this.state;
    return (
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-6 m-auto">
            <div className="card card-body mt-5 text-center">
              <center>
                <img
                  src={logo}
                  alt="Company Logo"
                  style={{ width: "300px", height: "auto" }}
                  className="text-center"
                  border="0"
                />
              </center>
              <h2 className="text-center my-4">Password Recovery Form</h2>
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="alert alert-success">{successMessage}</div>
              )}

              {showResetScreen === 0 && (
                <Fragment>
                  {showVerification === 1 && (
                    <form onSubmit={this.onSubmitVerificationCode}>
                      <div className="form-group row my-5">
                        <label className="col-sm-4 col-form-label text-right">
                          Verification Code
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            name="verificationCode"
                            onChange={this.onChange}
                            className="form-control"
                            autoComplete="section-red verificationCode"
                            value={verificationCode}
                          />
                        </div>
                      </div>
                      <button className="btn btn-primary btn-block">
                        <i className="fas fa-unlock-alt" /> SUBMIT
                      </button>
                    </form>
                  )}
                  {showVerification === 0 && (
                    <form onSubmit={this.onSubmitEmail}>
                      <div className="form-group row my-5">
                        <label className="col-sm-4 col-form-label text-right">
                          Email Address
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            name="username"
                            onChange={this.onChange}
                            className="form-control"
                            autoComplete="section-red username"
                            value={username}
                          />
                        </div>
                      </div>
                      <button className="btn btn-primary btn-block">
                        <i className="fas fa-unlock-alt" /> SUBMIT
                      </button>
                    </form>
                  )}
                </Fragment>
              )}

              {showResetScreen === 1 && (
                <form onSubmit={this.onFormSubmit}>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-right">
                      Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        name="password"
                        onChange={this.onChange}
                        className="form-control"
                        autoComplete="section-red new-password"
                        value={password}
                      />
                    </div>
                  </div>
                  <div className="form-group row my-5">
                    <label className="col-sm-4 col-form-label text-right">
                      Confirm Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="password"
                        name="passwordConfirm"
                        onChange={this.onChange}
                        className="form-control"
                        autoComplete="section-red new-password"
                        value={passwordConfirm}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={this.onSubmitPassword}
                  >
                    <i className="fas fa-unlock-alt" /> SUBMIT
                  </button>
                </form>
              )}
            </div>
            <p className="text-center">
              <Link to="/login" onClick={() => this.onCancel()}>
                <i className="fas fa-chevron-left" /> Back
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userData: state.auth.userData,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    forgotPass,
    saveForgotPass,
    checkVerificationCode,
  }),
)(ForgotPass);
