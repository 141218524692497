import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProfileNavigation from "./ProfileNavigation";
import ItemText from "../../component/itemText";

import {
  saveProfileCompany,
  getProfileCompany,
} from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import FormInput from "../../form/FormInput";

class ProfileCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      initialDataLoaded: false,

      companyName: "",
      contactEmail: "",
      companyPhone: "",
      companyAltPhone: "",

      companyAddress: "",
      companyAddress2: "",
      companyCity: "",
      companyState: "",
      companyZip: "",
      companyCountry: "",

      mailingAddress: "",
      mailingAddress2: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      mailingCountry: "",

      paymentSubject: "",
      successMessage: "",
      errors: {},
    };
  }
  componentDidMount() {
    this.props.getProfileCompany();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { initialDataLoaded } = this.state;
    if (
      initialDataLoaded === false &&
      typeof nextProps.profileData !== "undefined"
    ) {
      this.setState({
        initialDataLoaded: true,
        isLoaded: true,

        companyName: nextProps.profileData.companyName,
        contactEmail: nextProps.profileData.email,
        companyPhone: nextProps.profileData.phone,
        companyAltPhone: nextProps.profileData.phone2,

        companyAddress: nextProps.profileData.locationAddress1,
        companyAddress2: nextProps.profileData.locationAddress2,
        companyCity: nextProps.profileData.locationCity,
        companyState: nextProps.profileData.locationState,
        companyZip: nextProps.profileData.locationZip,
        companyCountry: nextProps.profileData.locationCountry,

        mailingAddress: nextProps.profileData.mailingAddress1,
        mailingAddress2: nextProps.profileData.mailingAddress2,
        mailingCity: nextProps.profileData.mailingCity,
        mailingState: nextProps.profileData.mailingState,
        mailingZip: nextProps.profileData.mailingZip,
        mailingCountry: nextProps.profileData.mailingCountry,
      });
    } else if (
      nextProps.saveData.success === "1" ||
      nextProps.saveData.success === 1
    ) {
      this.setState({ successMessage: nextProps.saveData.message });
    }
  }
  static propTypes = {
    profileData: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveProfileCompany: PropTypes.func.isRequired,
    getProfileCompany: PropTypes.func.isRequired,
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  saveProfileCompany = () => {
    const {
      companyName,
      contactEmail,
      companyPhone,
      companyAltPhone,
      companyAddress,
      companyAddress2,
      companyCity,
      companyState,
      companyZip,
      companyCountry,
      mailingAddress,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
      mailingCountry,
    } = this.state;

    const saveData = {
      companyName,
      contactEmail,
      companyPhone,
      companyAltPhone,

      companyAddress,
      companyAddress2,
      companyCity,
      companyState,
      companyZip,
      companyCountry,

      mailingAddress,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
      mailingCountry,
    };

    this.props.saveProfileCompany(saveData);
  };
  render() {
    const {
      isLoaded,
      companyName,
      contactEmail,
      companyPhone,
      companyAltPhone,

      companyAddress,
      companyAddress2,
      companyCity,
      companyState,
      companyZip,
      companyCountry,

      mailingAddress,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
      mailingCountry,

      successMessage,
      errorMessage,

      errors,
    } = this.state;
    if (isLoaded === true) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">Edit Profile</h1>
            </div>
            <ProfileNavigation />
            <div className="col-8">
              {successMessage && (
                <div className="col-12">
                  <div className="alert alert-success">{successMessage}</div>
                </div>
              )}
              {errorMessage && (
                <div className="col-12">
                  <div className="alert alert-danger">{errorMessage}</div>
                </div>
              )}

              <h2>Company Information</h2>
              <FormInput
                errors={errors.companyName}
                label="Company Name"
                type="display"
                id="companyName"
                name="companyName"
                value={companyName}
                placeholder="enter company name"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.contactEmail}
                label="Company Email"
                type="text"
                id="contactEmail"
                name="contactEmail"
                value={contactEmail}
                placeholder="enter company email"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyPhone}
                label="Company Phone"
                type="text"
                id="companyPhone"
                name="companyPhone"
                value={companyPhone}
                placeholder="enter company phone"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyAltPhone}
                label="Company Alt Phone"
                type="text"
                id="companyAltPhone"
                name="companyAltPhone"
                value={companyAltPhone}
                placeholder="enter company alt phone"
                onChange={this.onChange}
              />

              <h2>Company Office Address</h2>
              <ItemText
                errors={errors.companyAddress}
                label="Address"
                type="text"
                id="companyAddress"
                name="companyAddress"
                value={companyAddress}
                placeholder="enter address"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyAddress2}
                label="Address 2"
                type="text"
                id="companyAddress2"
                name="companyAddress2"
                value={companyAddress2}
                placeholder="enter address"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyCity}
                label="City"
                type="text"
                id="companyCity"
                name="companyCity"
                value={companyCity}
                placeholder="enter city"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyState}
                label="State"
                type="text"
                id="companyState"
                name="companyState"
                value={companyState}
                placeholder="enter state"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyZip}
                label="Zip"
                type="text"
                id="companyZip"
                name="companyZip"
                value={companyZip}
                placeholder="enter zip"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.companyCountry}
                label="Country"
                type="text"
                id="companyCountry"
                name="companyCountry"
                value={companyCountry}
                placeholder="enter country"
                onChange={this.onChange}
              />

              <h2>Company Mailing Address</h2>
              <FormInput
                errors={errors.mailingAddress}
                label="Address"
                type="text"
                id="mailingAddress"
                name="mailingAddress"
                value={mailingAddress}
                placeholder="enter address"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.mailingAddress2}
                label="Address 2"
                type="text"
                id="mailingAddress2"
                name="mailingAddress2"
                value={mailingAddress2}
                placeholder="enter address"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.mailingCity}
                label="City"
                type="text"
                id="mailingCity"
                name="mailingCity"
                value={mailingCity}
                placeholder="enter city"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.mailingState}
                label="State"
                type="text"
                id="mailingState"
                name="mailingState"
                value={mailingState}
                placeholder="enter state"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.mailingZip}
                label="Zip"
                type="text"
                id="mailingZip"
                name="mailingZip"
                value={mailingZip}
                placeholder="enter zip"
                onChange={this.onChange}
              />
              <FormInput
                errors={errors.mailingCountry}
                label="Country"
                type="Country"
                id="mailingCountry"
                name="mailingCountry"
                value={mailingCountry}
                placeholder="enter country"
                onChange={this.onChange}
              />

              <button
                type="submit"
                className="btn btn-secondary "
                onClick={() => this.saveProfileCompany()}
              >
                <i className="fas fa-save" /> SAVE
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state) => ({
  profileData: state.pages.profileData,
  saveData: state.pages.saveData,
});

export default connect(mapStateToProps, {
  saveProfileCompany,
  getProfileCompany,
})(ProfileCompany);
