import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { loadSortingNameList } from "../../../client/actions/apiActions";
import Spinner from "../../layout/Loading";
import List from "./FolderSort/List";

const SortFolders = (props) => {
  const [sortList, setSortList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSortList = () => {
    props.loadSortingNameList();
  };
  useEffect(() => {
    getSortList();
  }, []);

  useEffect(() => {
    if (typeof props.data !== "undefined" && props.data.folders !== sortList) {
      setSortList(props.data.folders);
      setLoading(false);
    }
  }, [props.data]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="text-center my-4">Sort Folders</h1>
        </div>

        <div className="col-12 mb-2">
          <Link to="/config" className="btn btn-sm btn-secondary">
            Back
          </Link>
        </div>

        <div className="container">
          <div className="row boxWhite">
            <div className="col-12">
              <List sortList={sortList} getSortList={getSortList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  loadSortingNameList,
})(SortFolders);
