import React, {Fragment} from 'react';

const FormInputText = ({name, value, label, helpText, error}) => {
    return (
        <div className="form-group row border-bottom pb-2">
            <label htmlFor={name} className="col-sm-4 m-0 pt-2 pb-2">{label}</label>
            <div className="col-sm-8 m-0 pt-2 pb-2">{value}</div>
            {helpText && <Fragment><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up"/> {error}</div>}
        </div>
    )
};

export default FormInputText