import React, {Fragment} from 'react';
import classnames from 'classnames';

const FormInputText = ({name, value, label, showOneColumn, placeholder, type, helpText, onChange, error}) => {
    if (typeof(showOneColumn) === 'undefined' || showOneColumn === '') showOneColumn='false';
    return (
        <div className="form-group row border-bottom pb-2">
            { showOneColumn === "true" && <Fragment>
                <label htmlFor={name} className="col-sm-12 m-0 pt-2 pb-2">{label}</label>
                <div className="col-sm-12">
                    <input
                        id={name}
                        name={name}
                        type={type}
                        value={value}
                        className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                        onChange={onChange}
                        aria-describedby={name+"Help"}
                        placeholder={placeholder}
                    />
                </div>
            </Fragment>}
            { showOneColumn === "false" && <Fragment>
                <label htmlFor={name} className="col-sm-4 m-0 pt-2 pb-2">{label}</label>
                <div className="col-sm-8">
                    <input
                        id={name}
                        name={name}
                        type={type}
                        value={value}
                        className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                        onChange={onChange}
                        aria-describedby={name+"Help"}
                        placeholder={placeholder}
                    />
                </div></Fragment>}

            {helpText && <Fragment><div className="col-4">&nbsp;</div><small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
            {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up"/> {error}</div>}
        </div>
    )
};

export default FormInputText