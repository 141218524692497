import React, { Component } from 'react';
import FormInputCheckboxElement from './FormInputCheckboxElement';
import classnames from "classnames";

class FormInputCheckbox extends Component {
    render () {
        const { name, value, label, helpText, valueOptions, selectedCheckboxes, handleCheckboxChange, error } = this.props;
        let optionsValue = '';
        if (typeof(value) !== 'undefined') optionsValue = value.toString();

        return (
            <div className="form-group row border-bottom pb-2">
                <label htmlFor={name} className="col-sm-4">{label}</label>
                <div className="col-sm-8">
                    {valueOptions ? (
                            valueOptions.map((options, index) =>
                                <div key={index} className="form-check form-check-inline">
                                    <label className="form-check-label" htmlFor={name + index}>
                                        <FormInputCheckboxElement
                                            className={classnames('form-check-input', { 'is-invalid': error})}
                                            type="checkbox"
                                            checked={optionsValue.indexOf("," + options.id.toString() + ",") !== -1 ? 'checked' : null }
                                            name={name}
                                            id={name + index}
                                            value={options.id}
                                            selectedCheckboxes={selectedCheckboxes}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                        {options.name}
                                    </label>
                                </div>
                            ))
                        : null
                    }
                </div>
                {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
                {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div> }
            </div>
        )
    }
}
export default FormInputCheckbox